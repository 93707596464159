import React, { useContext, useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TypeLeagueDate } from '../../../types';
import { Check, DeleteForever, Edit, Restore } from '@mui/icons-material';
import { useDeleteLeagueDate, usePostLeagueDate } from '../../../api';
import { DeleteLeagueDateDialog } from '../../_common';
import { DialogContext, LeagueContext } from '../../../providers';

export const LeagueDateList = ({
  leagueDates,
  leagueDatesAvailable,
}: {
  leagueDates: TypeLeagueDate[];
  leagueDatesAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { league } = useContext(LeagueContext);
  const [editLeagueDate, setEditLeagueDate] = useState<string>('');
  const [activeLeagueDate, setActiveLeagueDate] = useState<TypeLeagueDate>();
  const [leagueDateToDelete, setLeagueDateToDelete] =
    useState<TypeLeagueDate>();
  const { mutate: updateLeagueDate } = usePostLeagueDate();
  const { mutate: deleteLeagueDate } = useDeleteLeagueDate();
  const handleEdit = (leagueDate: TypeLeagueDate | null = null) => {
    if (leagueDate) {
      setEditLeagueDate(leagueDate?.date || '');
      setActiveLeagueDate(leagueDate);
    } else {
      setEditLeagueDate('');
      setActiveLeagueDate(undefined);
    }
  };

  const handleSave = () => {
    if (activeLeagueDate) {
      updateLeagueDate(
        {
          id: activeLeagueDate.id,
          date: editLeagueDate,
          league: activeLeagueDate.league,
        },
        {
          onSuccess: () => handleEdit(),
        },
      );
    }
  };

  const handleDeleteLeagueDateDialog = (leagueDate?: TypeLeagueDate) => {
    if (leagueDate) {
      setLeagueDateToDelete(leagueDate);
      openDialog('deleteLeagueDateDialog');
    } else {
      setLeagueDateToDelete(undefined);
      closeDialog('deleteLeagueDateDialog');
    }
  };

  if (!leagueDatesAvailable) {
    return <></>;
  }
  return (
    <>
      <TableContainer hidden={!leagueDatesAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leagueDates?.map((leagueDate) => (
              <TableRow key={leagueDate.id}>
                <TableCell>
                  {editLeagueDate && activeLeagueDate?.id === leagueDate?.id ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={`week-${leagueDate.id}`}
                      value={editLeagueDate}
                      label="Date"
                      type={'text'}
                      onChange={(event) =>
                        setEditLeagueDate(event.target.value)
                      }
                    />
                  ) : (
                    leagueDate?.date
                  )}
                </TableCell>
                <TableCell>
                  {editLeagueDate && activeLeagueDate?.id === leagueDate?.id ? (
                    <>
                      <IconButton onClick={handleSave}>
                        <Check />
                      </IconButton>
                      <IconButton onClick={() => handleEdit()}>
                        <Restore />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEdit(leagueDate)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteLeagueDateDialog(leagueDate)}
                        disabled={league?.matchesGenerated}
                      >
                        <DeleteForever />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {leagueDates?.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>No Dates Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {leagueDateToDelete && (
        <DeleteLeagueDateDialog
          deleteTitle={leagueDateToDelete?.date!!}
          contentText={`This will delete ${leagueDateToDelete?.date} and its time slots.`}
          deleteFn={() =>
            deleteLeagueDate(leagueDateToDelete, {
              onSuccess: () => handleDeleteLeagueDateDialog(),
            })
          }
        />
      )}
    </>
  );
};
