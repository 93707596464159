export const flexCenterStart = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
};

export const flexCenterCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
