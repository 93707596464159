import React, { useContext } from 'react';
import { AlertDialog } from './AlertDialog';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

type DeleteAnnouncementDialogProps = {
  announcementTitle: string;
  deleteFn: () => void;
};

export const DeleteAnnouncementDialog = ({
  announcementTitle,
  deleteFn,
}: DeleteAnnouncementDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteAnnouncementDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Delete ${announcementTitle}?`,
    contentText: `${announcementTitle} will be gone forever.`,
    dismissiveAction: (
      <Button
        color="primary"
        onClick={() => closeDialog('deleteAnnouncementDialog')}
      >
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={deleteFn}
      >
        Delete {announcementTitle}
      </Button>
    ),
  };

  return (
    <AlertDialog dialogProps={{ open: deleteAnnouncementDialog }} {...props} />
  );
};
