import { TypeDocument, TypeTag } from '../../../types';
import { Grid, IconButton, TextField } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AuthContext,
  PlannerContext,
  ProjectContext,
} from '../../../providers';
import { useGetTagsByType } from '../../../api';
import { DocumentDialog } from '../../_common';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';

export const EditDocumentCard = () => {
  const { performingAction } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const { activeDocument, handleSaveDocument } = useContext(PlannerContext);

  const [editDocumentName, setEditDocumentName] = useState<string>('');
  const [editDocumentDetails, setEditDocumentDetails] = useState<string>('');
  const [editDocumentTags, setEditDocumentTags] = useState<TypeTag | null>(
    null,
  );
  const { data: taskTags } = useGetTagsByType('task');
  const editDocumentNameRef = useRef<HTMLInputElement | null>(null);

  const handleEdit = (document: TypeDocument) => {
    setEditDocumentName(document?.name || '');

    setEditDocumentDetails(document?.details || '');
    setEditDocumentTags(document.tags?.length ? document.tags[0] : null);
  };

  const ensureTags = (tag: TypeTag | null) => {
    if (!tag) {
      return [];
    }
    return [
      {
        ...tag,
        type: 'task',
        position: 1,
        organization: profile?.organizationContext,
      },
    ];
  };

  const onHandleReset = () => {
    if (!activeDocument) {
      return;
    }
    handleEdit(activeDocument);
    editDocumentNameRef.current?.focus();
  };

  const onHandleSave = () => {
    handleSaveDocument({
      ...activeDocument,
      name: editDocumentName,
      details: editDocumentDetails,
      tags: ensureTags(editDocumentTags),
      profile: profile ?? undefined,
      organization: profile?.organizationContext,
    });
  };

  useEffect(() => {
    onHandleReset();
  }, [activeDocument]);

  const handleGetOptionLabel = (option: string | TypeTag) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.name || '';
  };

  const handleTagOptionEqualToValue = (
    option: TypeTag,
    value: TypeTag | string,
  ) => (typeof value === 'string' ? option.name === value : option === value);

  const handleTagsChange = (
    _event: React.SyntheticEvent,
    newValue: TypeTag | string | null,
  ) => {
    if (!newValue) {
      return;
    }
    setEditDocumentTags(
      typeof newValue === 'string'
        ? { name: newValue, type: 'task' }
        : newValue,
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onHandleSave();
    }
  };

  const mainFields = () => (
    <>
      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          clearOnBlur
          value={editDocumentTags}
          options={taskTags || []}
          getOptionLabel={handleGetOptionLabel}
          filterSelectedOptions
          isOptionEqualToValue={handleTagOptionEqualToValue}
          onChange={handleTagsChange}
          renderInput={(params) => (
            <TextField {...params} label="Project" placeholder="Project" />
          )}
          disabled={performingAction}
        />
      </Grid>
    </>
  );

  return (
    <DocumentDialog
      title={'Edit Document'}
      saveFn={onHandleSave}
      resetFn={onHandleReset}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder={'Document Name'}
            value={editDocumentName}
            onChange={(event) => setEditDocumentName(event.target.value)}
            onKeyDown={handleKeyPress}
            disabled={performingAction}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setEditDocumentName('');
                    editDocumentNameRef.current?.focus();
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        {mainFields()}
        <Grid item xs={12}>
          <MDEditor
            value={editDocumentDetails}
            onChange={(value) => setEditDocumentDetails(value || '')}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
          />
        </Grid>
      </Grid>
    </DocumentDialog>
  );
};
