import React, { useContext } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import { LeagueContext } from '../../../providers';
import { Loader, TabView } from '../../_common';
import {
  AccessTime,
  DateRange,
  EventAvailable,
  EventRepeat,
  Grass,
  Info,
  NoteAlt,
  Sports,
} from '@mui/icons-material';
import { LeagueFields } from '../LeagueField';
import { LeagueDates } from '../LeagueDate';
import { LeagueTimes } from '../LeagueTime';
import { LeagueTeams } from './index';
import { TypeTab } from '../../../types';
import { LeagueDetails } from './LeagueDetails';
import { Coaches } from '../Coach';
import { LeagueTimeSlots } from './LeagueTimeSlots';
import { Divisions } from '../Division/Divisions';
import { LeagueDays } from '../LeagueDay';

export const LeagueSetup = () => {
  const { isLeagueReady } = useContext(LeagueContext);

  const tabs: TypeTab[] = [
    { name: 'Details', icon: <Info />, component: LeagueDetails },
    { name: 'Coaches', icon: <Sports />, component: Coaches },
    { name: 'Fields', icon: <Grass />, component: LeagueFields },
    { name: 'Dates', icon: <DateRange />, component: LeagueDates },
    { name: 'Times', icon: <AccessTime />, component: LeagueTimes },
    {
      name: 'Divisions',
      icon: <GroupIcon />,
      component: Divisions,
    },
    { name: 'Teams', icon: <NoteAlt />, component: LeagueTeams },
    { name: 'Time Slots', icon: <EventRepeat />, component: LeagueTimeSlots },
    {
      name: 'Practice Days',
      icon: <EventAvailable />,
      component: LeagueDays,
    },
  ];

  if (!isLeagueReady) {
    return <Loader />;
  }

  return <TabView tabs={tabs} />;
};
