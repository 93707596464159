import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const styles = {
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
};

export const Loader = () => {
  return (
    <Box sx={{ ...styles.center }}>
      <CircularProgress />
    </Box>
  );
};
