import React, { useMemo } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
// import ReactMapboxGl, { Feature, Layer } from 'react-mapbox-gl';
import { Box, Container, Typography } from '@mui/material';
import { useGeolocated } from 'react-geolocated';
import { Loader } from '../../_common';
import { MapboxComponent } from './MapboxComponent';
import { useGetOrders } from '../../../api';
// import mapboxgl from 'mapbox-gl';
// // eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass =
//   require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export const Directions = () => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  const { data: ordersData } = useGetOrders();

  const orders = useMemo(
    () => ordersData?.filter((order) => order.available) || [],
    [ordersData],
  );

  console.log(
    isGeolocationEnabled,
    isGeolocationAvailable,
    coords,
    coords?.latitude,
    coords?.longitude,
  );

  if (!isGeolocationAvailable || !isGeolocationEnabled) {
    return <div>Your browser does not support Geolocation</div>;
  }

  if (!coords) {
    return <Loader />;
  }

  return (
    <Box m={2}>
      <Container>
        {orders.length > 0 ? (
          <MapboxComponent
            lat={coords.latitude}
            lng={coords.longitude}
            zoom={12}
            orders={orders}
          />
        ) : (
          <Typography variant={'body1'}>
            Generate an order to see the map!
          </Typography>
        )}
      </Container>
    </Box>
  );
};
