import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TypeCoach,
  TypeDivision,
  TypeLeague,
  TypeLeagueDate,
  TypeLeagueDay,
  TypeLeagueField,
  TypeLeagueTime,
  TypeLeagueWeek,
  TypePracticeDivisionGroup,
} from '../../types';
import {
  useGetCoaches,
  useGetDivisions,
  useGetLeague,
  useGetLeagueDates,
  useGetLeagueDays,
  useGetLeagueFields,
  useGetLeagueTimes,
  useGetLeagueWeeks,
  useGetPracticeDivisionGroups,
} from '../../api';
import { AuthContext } from '../AuthProvider';

type LeagueContextType = {
  league: TypeLeague | undefined;
  refetchLeague: () => void;
  selectedDivision: TypeDivision | null;
  setSelectedDivision: (_division: TypeDivision | null) => void;
  divisionEditScreen: EditScreenType;
  setDivisionEditScreen: (_editScreen: EditScreenType) => void;
  divisions: TypeDivision[];
  practiceDivisionGroups: TypePracticeDivisionGroup[];
  weeks: TypeLeagueWeek[];
  fields: TypeLeagueField[];
  dates: TypeLeagueDate[];
  times: TypeLeagueTime[];
  days: TypeLeagueDay[];
  coaches: TypeCoach[];
  isLeagueReady: boolean;
};

type EditScreenType = 'teams' | 'timeSlots';

export const LeagueContext = createContext<LeagueContextType>({
  league: undefined,
  refetchLeague: () => {},
  selectedDivision: null,
  setSelectedDivision: (_division: TypeDivision | null) => {},
  divisionEditScreen: 'teams',
  setDivisionEditScreen: (_editScreen: EditScreenType) => {},
  divisions: [],
  practiceDivisionGroups: [],
  weeks: [],
  fields: [],
  dates: [],
  times: [],
  days: [],
  coaches: [],
  isLeagueReady: false,
});

export type LeagueProviderProps = {
  leagueId: number;
  children: React.ReactNode;
};

export const LeagueProvider = ({ leagueId, children }: LeagueProviderProps) => {
  const { profile } = useContext(AuthContext);
  const [league, setLeague] = useState<TypeLeague>({});
  const [selectedDivision, setSelectedDivision] = useState<TypeDivision | null>(
    null,
  );
  const [divisionEditScreen, setDivisionEditScreen] =
    useState<EditScreenType>('teams');

  const { refetch: refetchLeague } = useGetLeague(leagueId, {
    onSuccess: (data) => setLeague(data),
    refetchOnWindowFocus: false,
  });
  const { data: divisionsData, isLoading: divisionsLoading } = useGetDivisions(
    leagueId,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => !selectedDivision && setSelectedDivision(data[0]),
    },
  );
  const {
    data: practiceDivisionGroupsData,
    isLoading: practiceDivisionGroupsLoading,
  } = useGetPracticeDivisionGroups(Number(league.id!!));
  const {
    data: weeksData,
    isLoading: weeksLoading,
    refetch: refetchLeagueWeeks,
  } = useGetLeagueWeeks(
    leagueId,

    { refetchOnWindowFocus: false },
  );
  const {
    data: fieldsData,
    isLoading: fieldsLoading,
    refetch: refetchLeagueFields,
  } = useGetLeagueFields(leagueId, { refetchOnWindowFocus: false });
  const {
    data: datesData,
    isLoading: datesLoading,
    refetch: refetchLeagueDates,
  } = useGetLeagueDates(leagueId, { refetchOnWindowFocus: false });
  const {
    data: timesData,
    isLoading: timesLoading,
    refetch: refetchLeagueTimes,
  } = useGetLeagueTimes(leagueId, { refetchOnWindowFocus: false });
  const {
    data: daysData,
    isLoading: daysLoading,
    refetch: refetchLeagueDays,
  } = useGetLeagueDays(leagueId, { refetchOnWindowFocus: false });
  const {
    data: coachesData,
    isLoading: coachesLoading,
    refetch: refetchCoaches,
  } = useGetCoaches(leagueId, { refetchOnWindowFocus: false });

  const wrapSetSelectedDivision = (division: TypeDivision | null) => {
    setSelectedDivision(division);
  };
  const wrapSetDivisionEditScreen = (editScreen: EditScreenType) => {
    setDivisionEditScreen(editScreen);
  };
  const leagueMemo = useMemo(() => league, [league]);
  const selectedDivisionMemo = useMemo(
    () => selectedDivision,
    [selectedDivision],
  );
  const divisionEditScreenMemo = useMemo(
    () => divisionEditScreen,
    [divisionEditScreen],
  );

  useEffect(() => {
    refetchLeague();
    refetchLeagueWeeks();
    refetchLeagueFields();
    refetchLeagueDates();
    refetchLeagueTimes();
    refetchLeagueDays();
    refetchCoaches();
  }, [profile?.organizationContext]);

  return (
    <LeagueContext.Provider
      value={{
        league: leagueMemo,
        refetchLeague,
        selectedDivision: selectedDivisionMemo,
        setSelectedDivision: wrapSetSelectedDivision,
        divisionEditScreen: divisionEditScreenMemo,
        setDivisionEditScreen: wrapSetDivisionEditScreen,
        practiceDivisionGroups: practiceDivisionGroupsData || [],
        divisions: divisionsData || [],
        weeks: weeksData || [],
        fields: fieldsData || [],
        dates: datesData || [],
        times: timesData || [],
        days: daysData || [],
        coaches: coachesData || [],
        isLeagueReady:
          !divisionsLoading &&
          !practiceDivisionGroupsLoading &&
          !weeksLoading &&
          !fieldsLoading &&
          !datesLoading &&
          !timesLoading &&
          !daysLoading &&
          !coachesLoading,
      }}
    >
      {children}
    </LeagueContext.Provider>
  );
};
