import React, { useEffect, useMemo, useRef } from 'react';
import mapboxgl, { LngLat, Map as Mapbox } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Paper } from '@mui/material';
import { useGetMapboxRoute } from '../../../api';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { TypeOrder } from '../../../types';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!!;

type MapboxComponentProps = {
  lat: number;
  lng: number;
  zoom: number;
  orders: TypeOrder[];
};

export const MapboxComponent = ({
  lat,
  lng,
  zoom,
  orders,
}: MapboxComponentProps) => {
  const map = useRef<Mapbox | null>(null);

  const coords = useMemo(() => {
    const start = new LngLat(lng, lat);
    const allordersl = orders.map((order) => {
      return new LngLat(
        order.mapboxAddress!.longitude!,
        order.mapboxAddress!.latitude!,
      );
    });
    return [start, ...allordersl];
  }, [lat, lng, orders]);

  const bounds = useMemo(
    () =>
      coords.reduce((bounds, coord) => {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coords[0], coords[0])),
    [coords],
  );

  console.log(coords, bounds);
  console.log(coords.map((coord) => `${coord.lng},${coord.lat}`).join(';'));

  const { data: mapboxDirections } = useGetMapboxRoute(coords, {
    enabled: coords.length > 1,
  });

  useEffect(() => {
    if (!map.current && mapboxDirections) {
      map.current = new mapboxgl.Map({
        container: 'mapbox-component',
        style: 'mapbox://styles/mapbox/streets-v11',
        bounds: bounds,
        zoom: zoom,
      });

      // Add our navigation control (the +/- zoom buttons)
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

      const geojson: Feature<Geometry, GeoJsonProperties> = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: mapboxDirections.routes[0].geometry.coordinates,
        },
      };

      console.log(geojson, map.current);
      // if (map.current.getSource('route')) {
      // map.current.getSource('route').setData(geojson);
      // } else {
      map.current.on('style.load', function () {
        // Your code that interacts with the map or its features
        map.current!.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: geojson,
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#3887be',
            'line-width': 5,
            'line-opacity': 0.75,
          },
        });
      });
    }

    if (map.current) {
      // Map onload event
      map.current.on('load', () => {
        // Nifty code to force map to fit inside container when it loads
        map.current!.resize();
      });
    }

    // Clean up on unmount
    // return () => map.current.remove();
  }, [zoom, mapboxDirections]);

  return (
    <Paper
      className="map-container"
      id={'mapbox-component'}
      sx={{ height: '90vh' }}
    />
  );
};
