import { TypeEditTaskField, TypeTask } from '../../../types';
import {
  Box,
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import React, { useContext } from 'react';
import { ProjectContext } from '../../../providers';
import { format, isBefore, parseISO } from 'date-fns';
import { DateView, DurationView } from '../../_common';
import { useSortable } from '@dnd-kit/sortable';
import { UniqueIdentifier } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { addDays } from '@fullcalendar/core/internal';

type ViewTaskProps = {
  sortBy: TypeEditTaskField;
  showChips: boolean;
  task: TypeTask;
  onSetEditTask: (
    _task: TypeTask,
    _taskFocusField: TypeEditTaskField | null,
  ) => void;
  onSave: (_task: TypeTask) => void;
};

export const ViewTaskCard = ({
  sortBy,
  showChips,
  task,
  onSetEditTask,
  onSave,
}: ViewTaskProps) => {
  const { performingAction } = useContext(ProjectContext);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: task.id as UniqueIdentifier });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const actions = () => (
    <IconButton
      onClick={() =>
        onSave({
          ...task,
          completedAt: task.completedAt
            ? undefined
            : format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`),
        })
      }
      disabled={performingAction}
      edge={'end'}
    >
      {task.completedAt ? <CheckBox /> : <CheckBoxOutlineBlank />}
    </IconButton>
  );

  const expansionView = () => {
    if (sortBy === 'completedAt') {
      return <DateView title={'Completed'} date={task.completedAt} />;
    }
    if (sortBy === 'deletedAt') {
      return <DateView title={'Deleted'} date={task.deletedAt} />;
    }
    if (sortBy === 'updatedAt') {
      return <DateView title={'Updated'} date={task.updatedAt} />;
    }
    if (sortBy === 'snoozeUntil') {
      return <DateView title={'Snoozed'} date={task.snoozeUntil} />;
    }
    if (sortBy === 'durationEstimate') {
      return (
        <DurationView title={'Duration'} duration={task.durationEstimate} />
      );
    }
    if (task.dueAt) {
      const color = isBefore(parseISO(task.dueAt), new Date())
        ? 'error'
        : isBefore(parseISO(task.dueAt), addDays(new Date(), 1))
        ? 'warning'
        : 'default';
      return <DateView title={'Due'} date={task.dueAt} color={color} />;
    }
    if (task.tags) {
      return task.tags.map((tag) => (
        <Box key={tag.id} sx={{ marginRight: 1 }}>
          <Chip label={tag.name} />
        </Box>
      ));
    }
    return null;
  };

  return (
    <Paper
      elevation={3}
      sx={{ width: '100%', marginBottom: 1 }}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <ListItem
        sx={{
          pb: 0,
          pt: 0,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {sortBy === 'priorityValue' && (
            <DragIndicatorIcon {...listeners} style={{ cursor: 'grab' }} />
          )}
          <ListItemText
            onClick={() => onSetEditTask(task, 'name')}
            primary={task.name}
            sx={{
              cursor: 'pointer',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflowX: 'clip',
              margin: '0 auto',
              display: 'inline-grid',
              maxWidth: '100%',
            }}
          ></ListItemText>
          {showChips && expansionView()}
          {actions()}
        </Box>
      </ListItem>
    </Paper>
  );
};
