import React, { useContext, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { MatchSchedule, SchedulePDFs } from './index';
import { LeagueContext } from '../../../providers';
import { Loader } from '../../_common';
import { CalendarMonth, CheckCircle, PictureAsPdf } from '@mui/icons-material';
import { LeagueWeekTimeSlotList } from '../TimeSlot/LeagueWeekTimeSlotList';
import { ValidationList } from '../ValidationList';

export const Matches = () => {
  const { isLeagueReady } = useContext(LeagueContext);

  const [selectedTab, setSelectedTab] = useState(0);

  if (!isLeagueReady) {
    return <Loader />;
  }
  return (
    <>
      <Tabs
        style={{ overflow: 'initial', minHeight: 'initial' }}
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        variant="scrollable"
        onChange={(event, value) => setSelectedTab(value)}
      >
        <Tab key={0} icon={<CalendarMonth />} label={'Schedule'} />
        <Tab key={1} icon={<PictureAsPdf />} label={'PDFs'} />
        <Tab key={2} icon={<CalendarMonth />} label={'Rescheduling'} />
        <Tab key={2} icon={<CheckCircle />} label={'Validation'} />
      </Tabs>
      <Box mt={2}>
        {selectedTab === 0 && <MatchSchedule />}
        {selectedTab === 1 && <SchedulePDFs />}
        {selectedTab === 2 && <LeagueWeekTimeSlotList />}
        {selectedTab === 3 && <ValidationList />}
      </Box>
    </>
  );
};
