import React, { useContext } from 'react';
import { AlertDialog } from './AlertDialog';
import { ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

type CancelDialogProps = {
  cancelTitle: string;
  contentText: string;
  cancelFn: () => void;
  closeFn: () => void;
  open: boolean;
};

export const CancelDialog = ({
  cancelTitle,
  contentText,
  cancelFn,
  closeFn,
  open,
}: CancelDialogProps) => {
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Cancel ${cancelTitle}?`,
    contentText: contentText,
    dismissiveAction: (
      <Button color="primary" variant="outlined" onClick={closeFn}>
        Close
      </Button>
    ),
    confirmingAction: (
      <Button
        color="secondary"
        variant="contained"
        disabled={performingAction}
        onClick={cancelFn}
      >
        Cancel {cancelTitle}
      </Button>
    ),
  };

  return <AlertDialog dialogProps={{ open }} {...props} />;
};
