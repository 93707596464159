import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  useGetMaterialRecipes,
  useGetMaterials,
  useGetMeasurements,
  useGetTagsByType,
} from '../../api';
import {
  TypeMaterial,
  TypeMaterialOption,
  TypeMeasurement,
  TypeRecipe,
  TypeTag,
} from '../../types';
import { AuthContext } from '../AuthProvider';

type TypeRecipeMultiplier = {
  [key: number]: number;
};

type ChefContextType = {
  editMode: boolean;
  setEditMode: (_editMode: boolean) => void;
  getRecipeMultiplier: (_recipeId: number) => number;
  setRecipeMultiplier: (_recipeId: number, _up: boolean) => void;
  allRecipeTags: TypeTag[];
  measurements: TypeMeasurement[];
  materials: TypeMaterial[];
  materialOptions: TypeMaterialOption[];
  materialRecipes: TypeRecipe[];
  isChefReady: boolean;
  refetchAll: () => void;
};

export const ChefContext = createContext<ChefContextType>({
  editMode: false,
  setEditMode: (_editMode: boolean) => {},
  getRecipeMultiplier: (_recipeId: number) => 1,
  setRecipeMultiplier: (_recipeId: number, _up: boolean) => {},
  allRecipeTags: [],
  measurements: [],
  materials: [],
  materialOptions: [],
  materialRecipes: [],
  isChefReady: false,
  refetchAll: () => {},
});

export type ChefProviderProps = {
  children: React.ReactNode;
};

export const ChefProvider = ({ children }: ChefProviderProps) => {
  const { profile } = useContext(AuthContext);
  const [editMode, setEditMode] = useState<boolean>(true);
  const [recipeMultiplierMap, setRecipeMultiplierMap] =
    useState<TypeRecipeMultiplier>({});
  const [allRecipeTags, setAllRecipeTags] = useState<TypeTag[]>([]);
  const [measurements, setMeasurements] = useState<TypeMeasurement[]>([]);
  // const [materials, setMaterials] = useState<TypeMaterial[]>([]);
  const [materialOptions, setMaterialOptions] = useState<TypeMaterialOption[]>(
    [],
  );
  const [materialRecipes, setMaterialRecipes] = useState<TypeRecipe[]>([]);

  const { isLoading: recipeTagsLoading, refetch: refetchTagsByType } =
    useGetTagsByType('recipe', {
      onSuccess: (data) => setAllRecipeTags(data),
      refetchOnWindowFocus: false,
    });
  const { isLoading: measurementsLoading, refetch: refetchMeasurements } =
    useGetMeasurements({
      onSuccess: (data) => setMeasurements(data),
      refetchOnWindowFocus: false,
    });
  const {
    data: materials,
    isLoading: materialsLoading,
    refetch: refetchMaterials,
  } = useGetMaterials({
    refetchOnWindowFocus: false,
  });
  const { isLoading: materialRecipesLoading, refetch: refetchMaterialRecipes } =
    useGetMaterialRecipes({
      onSuccess: (data) => setMaterialRecipes(data),
      refetchOnWindowFocus: false,
    });

  const refetchAll = () => {
    refetchTagsByType();
    refetchMeasurements();
    refetchMaterials();
    refetchMaterialRecipes();
  };

  useEffect(() => {
    refetchAll();
  }, [profile?.organizationContext]);

  useEffect(() => {
    if (materials) {
      const options: TypeMaterialOption[] = materials.map((material) => {
        return {
          id: material.id,
          name: material.materialName,
          material: material,
        };
      });
      setMaterialOptions(options);
    }
  }, [materials]);

  const getRecipeMultiplier = (recipeId: number): number => {
    return recipeMultiplierMap[recipeId] || 1;
  };

  const setRecipeMultiplier = (recipeId: number, up: boolean) => {
    let multiplier = getRecipeMultiplier(recipeId);
    if (up) {
      if (multiplier < 1) {
        multiplier = 1;
      } else {
        multiplier += 1;
      }
    } else {
      if (multiplier <= 1) {
        multiplier = 0.5;
      } else {
        multiplier -= 1;
      }
    }
    setRecipeMultiplierMap({
      ...recipeMultiplierMap,
      [recipeId]: multiplier,
    });
  };

  return (
    <ChefContext.Provider
      value={{
        editMode,
        setEditMode,
        getRecipeMultiplier,
        setRecipeMultiplier,
        allRecipeTags,
        measurements,
        materials: materials || [],
        materialOptions,
        materialRecipes,
        isChefReady:
          !recipeTagsLoading &&
          !measurementsLoading &&
          !materialsLoading &&
          !materialRecipesLoading,
        refetchAll,
      }}
    >
      {children}
    </ChefContext.Provider>
  );
};
