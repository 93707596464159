import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TypeAppointment,
  TypeAppointmentCalendar,
  TypeAppointmentCalendarGroup,
  TypeAppointmentType,
  TypeAvailabilityDTO,
  TypeProfile,
  TypeSquareAppointmentBalance,
  TypeSquareCatalogItem,
  TypeSquareOrder,
  TypeTimeSpanDTO,
} from '../../types';
import {
  useCancelAppointment,
  useGetAllSquareCatalogItems,
  useGetAppointmentCalendarGroups,
  useGetAppointmentCalendars,
  useGetAppointmentTypes,
  useGetClientAppointments,
  useGetSquareAppointmentBalance,
  usePostAppointment,
  usePostFindAvailability,
  usePostFindNextAvailability,
  usePostSquareOrder,
} from '../../api';
import moment from 'moment/moment';
import { ProjectContext } from '../ProjectProvider';
import { DialogContext } from '../DialogProvider';
import { AppointmentCalendarContext } from './AppointmentCalendarProvider';
import { AuthContext } from '../AuthProvider';
import { stableSort } from '../../components';

type AppointmentContextType = {
  squareApplicationId: string;
  squareLocationId: string;
  timeSpanList: TypeTimeSpanDTO[];
  appointmentTypes: TypeAppointmentType[];
  appointmentCalendars: TypeAppointmentCalendar[];
  appointmentCalendarGroups: TypeAppointmentCalendarGroup[];
  appointments: TypeAppointment[];
  appointmentTimeframe: 'upcoming' | 'past';
  toggleAppointmentTimeframe: () => void;
  disableCreateAppointment: boolean;
  sufficientBalance: boolean;
  createAppointment: (_callback?: () => void) => void;
  completeSquareOrder: (_token: string | undefined) => void;
  appointmentAction: string;
  handleCancelAppointment: (_appointment: TypeAppointment) => void;
  handleFindAvailability: (_date: Date | null) => void;
  handleFindNextAvailableAppointment: () => void;
  appointmentHeadlineDetails: (_appointment: TypeAppointment) => string;
  appointmentDetails: (_appointment: TypeAppointment) => string;
  selectedAppointmentType: TypeAppointmentType | null;
  setSelectedAppointmentType: (_type: TypeAppointmentType | null) => void;
  selectedAppointmentCalendar: TypeAppointmentCalendar | null;
  setSelectedAppointmentCalendar: (
    _calendar: TypeAppointmentCalendar | null,
  ) => void;
  selectedAppointmentCalendarGroup: TypeAppointmentCalendarGroup | null;
  setSelectedAppointmentCalendarGroup: (
    _calendarGroup: TypeAppointmentCalendarGroup | null,
  ) => void;
  selectedDate: Date | null;
  setSelectedDate: (_date: Date | null) => void;
  selectedTimeSpan: TypeTimeSpanDTO | null;
  setSelectedTimeSpan: (_timeSpan: TypeTimeSpanDTO | null) => void;
  selectedAppointmentClientProfile: TypeProfile | null;
  setSelectedAppointmentClientProfile: (_profile: TypeProfile | null) => void;
  editAppointment: TypeAppointment | null;
  setEditAppointment: (_appointment: TypeAppointment | null) => void;
  cancelAppointment: TypeAppointment | null;
  setCancelAppointment: (_appointment: TypeAppointment | null) => void;
  createdAppointment: TypeAppointment | null;
  setCreatedAppointment: (_appointment: TypeAppointment | null) => void;
  createdSquareOrder: TypeSquareOrder | null;
  setCreatedSquareOrder: (_squareOrder: TypeSquareOrder | null) => void;
  newAppointment: TypeAppointment;
  resetAppointmentScheduler: () => void;
  catalogItems: TypeSquareCatalogItem[];
  selectedCatalogItem: TypeSquareCatalogItem | null;
  setSelectedCatalogItem: (_catalogItem: TypeSquareCatalogItem | null) => void;
  appointmentBalance: TypeSquareAppointmentBalance | null;
  showAppointmentTypes: boolean;
  setShowAppointmentTypes: (_showCatalogItems: boolean) => void;
  showCatalogItems: boolean;
  setShowCatalogItems: (_showCatalogItems: boolean) => void;
};

export const AppointmentContext = createContext<AppointmentContextType>({
  squareApplicationId: '',
  squareLocationId: '',
  timeSpanList: [],
  appointmentCalendarGroups: [],
  appointmentCalendars: [],
  appointmentTypes: [],
  appointments: [],
  appointmentTimeframe: 'upcoming',
  toggleAppointmentTimeframe: () => {},
  disableCreateAppointment: false,
  sufficientBalance: false,
  createAppointment: (_callback) => {},
  completeSquareOrder: (_token) => {},
  appointmentAction: '',
  handleCancelAppointment: (_appointment) => {},
  handleFindAvailability: (_date) => {},
  handleFindNextAvailableAppointment: () => {},
  appointmentHeadlineDetails: (_appointment) => '',
  appointmentDetails: (_appointment) => '',
  selectedAppointmentType: null,
  setSelectedAppointmentType: (_type) => {},
  selectedAppointmentCalendar: null,
  setSelectedAppointmentCalendar: (_calendar) => {},
  selectedAppointmentCalendarGroup: null,
  setSelectedAppointmentCalendarGroup: (_calendarGroup) => {},
  selectedDate: null,
  setSelectedDate: (_date) => {},
  selectedTimeSpan: null,
  setSelectedTimeSpan: (_timeSpan) => {},
  selectedAppointmentClientProfile: null,
  setSelectedAppointmentClientProfile: (_profile) => {},
  editAppointment: null,
  setEditAppointment: (_appointment) => {},
  cancelAppointment: null,
  setCancelAppointment: (_appointment) => {},
  createdAppointment: null,
  setCreatedAppointment: (_appointment) => {},
  createdSquareOrder: null,
  setCreatedSquareOrder: (_squareOrder) => {},
  newAppointment: {} as TypeAppointment,
  resetAppointmentScheduler: () => {},
  catalogItems: [],
  selectedCatalogItem: null,
  setSelectedCatalogItem: (_catalogItem) => {},
  appointmentBalance: null,
  showAppointmentTypes: false,
  setShowAppointmentTypes: (_showAppointmentTypes) => {},
  showCatalogItems: false,
  setShowCatalogItems: (_showCatalogItems) => {},
});

export type AppointmentProviderProps = {
  children: React.ReactNode;
};

export const AppointmentProvider = ({ children }: AppointmentProviderProps) => {
  const { setSnackbar } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const { findAppointments } = useContext(AppointmentCalendarContext);
  const { closeDialog } = useContext(DialogContext);

  const squareApplicationId = process.env.REACT_APP_SQUARE_APPLICATION_ID || '';
  const squareLocationId = process.env.REACT_APP_SQUARE_LOCATION_ID || '';

  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<TypeAppointmentType | null>(null);
  const [selectedAppointmentCalendar, setSelectedAppointmentCalendar] =
    useState<TypeAppointmentCalendar | null>(null);
  const [
    selectedAppointmentCalendarGroup,
    setSelectedAppointmentCalendarGroup,
  ] = useState<TypeAppointmentCalendarGroup | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTimeSpan, setSelectedTimeSpan] =
    useState<TypeTimeSpanDTO | null>(null);
  const [
    selectedAppointmentClientProfile,
    setSelectedAppointmentClientProfile,
  ] = useState<TypeProfile | null>(null);
  const [timeSpanList, setTimeSpanList] = useState<TypeTimeSpanDTO[]>([]);
  const [appointments, setAppointments] = useState<TypeAppointment[]>([]);
  const [appointmentBalance, setAppointmentBalance] =
    useState<TypeSquareAppointmentBalance | null>(null);
  const [appointmentTimeframe, setAppointmentTimeframe] = useState<
    'upcoming' | 'past'
  >('upcoming');
  const [editAppointment, setEditAppointment] =
    useState<TypeAppointment | null>(null);
  const [cancelAppointment, setCancelAppointment] =
    useState<TypeAppointment | null>(null);
  const [createdAppointment, setCreatedAppointment] =
    useState<TypeAppointment | null>(null);
  const [createdSquareOrder, setCreatedSquareOrder] =
    useState<TypeSquareOrder | null>(null);
  const [selectedCatalogItem, setSelectedCatalogItem] =
    useState<TypeSquareCatalogItem | null>(null);
  const [showCatalogItems, setShowCatalogItems] = useState<boolean>(false);
  const [showAppointmentTypes, setShowAppointmentTypes] =
    useState<boolean>(false);

  const { data: catalogItems } = useGetAllSquareCatalogItems();
  const { data: appointmentTypes } = useGetAppointmentTypes();
  const { data: appointmentCalendars } = useGetAppointmentCalendars();
  const { data: appointmentCalendarGroups } = useGetAppointmentCalendarGroups();
  const { mutate: postAppointment } = usePostAppointment();
  const { mutate: postSquareOrder } = usePostSquareOrder();
  const { mutate: postFindAvailability } = usePostFindAvailability();
  const { mutate: postFindNextAvailableAppointment } =
    usePostFindNextAvailability();
  const { refetch: refetchClientAppointments } = useGetClientAppointments(
    {
      profileId: selectedAppointmentClientProfile?.id!!,
      timeframe: appointmentTimeframe,
      canceled: false,
    },
    {
      enabled: !!selectedAppointmentClientProfile,
      onSuccess: (data) => setAppointments(data),
    },
  );
  const { refetch: refetchSquareAppointmentBalance } =
    useGetSquareAppointmentBalance(selectedAppointmentClientProfile!!, {
      enabled: !!selectedAppointmentClientProfile,
      onSuccess: (data) => setAppointmentBalance(data),
    });
  const { mutate: postCancelAppointment } = useCancelAppointment();

  const disableCreateAppointment =
    !selectedAppointmentType ||
    !(selectedAppointmentCalendar || selectedAppointmentCalendarGroup) ||
    !selectedDate ||
    !selectedTimeSpan ||
    !selectedAppointmentClientProfile;

  const sufficientBalance = useMemo(() => {
    if (selectedAppointmentClientProfile !== profile) {
      return true;
    }
    if (
      !appointmentBalance ||
      !selectedAppointmentType ||
      !selectedAppointmentType.hours
    )
      return false;
    if (editAppointment) {
      return appointmentBalance?.appointmentHoursRemaining >= 0;
    }
    return (
      appointmentBalance?.appointmentHoursRemaining >=
      selectedAppointmentType?.hours
    );
  }, [appointmentBalance, selectedAppointmentType]);
  const appointmentAction = editAppointment ? 'Reschedule' : 'Schedule';

  const newAppointment: TypeAppointment = {
    ...editAppointment,
    appointmentCalendar:
      selectedAppointmentCalendar ?? selectedTimeSpan?.calendar,
    appointmentCalendarGroup: selectedAppointmentCalendarGroup || undefined,
    startAt: selectedTimeSpan?.startAt,
    endAt: selectedTimeSpan?.endAt,
    appointmentType: selectedAppointmentType || undefined,
    profile: selectedAppointmentClientProfile || undefined,
    organization:
      selectedAppointmentClientProfile?.organizationContext || undefined,
  };

  const resetAppointmentScheduler = () => {
    setSelectedAppointmentType(null);
    setSelectedAppointmentCalendar(null);
    setSelectedAppointmentCalendarGroup(null);
    setSelectedDate(null);
    setSelectedTimeSpan(null);
    setTimeSpanList([]);
    setCreatedAppointment(null);
    setEditAppointment(null);
  };

  const createAppointment = () => {
    if (disableCreateAppointment) {
      return;
    }
    postAppointment(newAppointment, {
      onSuccess: () => {
        setCreatedAppointment(newAppointment);
        setSnackbar(
          `Appointment ${appointmentAction.toLowerCase()}d successfully!`,
        );
      },
      onError: () =>
        setSnackbar(
          `Failed to ${appointmentAction.toLowerCase()} appointment`,
          'error',
        ),
      onSettled: () => {
        refetchSquareAppointmentBalance();
        refetchClientAppointments();
        findAppointments();
      },
    });
  };

  const completeSquareOrder = (token: string | undefined) => {
    if (!selectedCatalogItem || !selectedAppointmentClientProfile || !token) {
      return;
    }
    console.log('Card nonce received:', token);
    const squareOrder: TypeSquareOrder = {
      idempotencyKey: token,
      profile: selectedAppointmentClientProfile ?? undefined,
      squareCatalogItemList: selectedCatalogItem ? [selectedCatalogItem] : [],
    };
    postSquareOrder(squareOrder, {
      onSuccess: () => {
        setCreatedSquareOrder(squareOrder);
        setSelectedCatalogItem(null);
        setSnackbar(`Order completed successfully!`);
      },
      onError: () => setSnackbar(`Failed to complete order`, 'error'),
      onSettled: () => {
        refetchSquareAppointmentBalance();
        refetchClientAppointments();
        findAppointments();
      },
    });
  };

  const getAvailability = (date: Date | null): TypeAvailabilityDTO | null => {
    if (
      !selectedAppointmentClientProfile ||
      !selectedAppointmentType ||
      !(selectedAppointmentCalendar || selectedAppointmentCalendarGroup) ||
      !(selectedDate || date)
    )
      return null;
    return {
      calendarId: selectedAppointmentCalendar?.id,
      calendarGroupId: selectedAppointmentCalendarGroup?.id,
      day: date ?? selectedDate,
      appointmentTypeId: selectedAppointmentType?.id,
      profileId: selectedAppointmentClientProfile?.id,
    } as TypeAvailabilityDTO;
  };

  const toggleAppointmentTimeframe = () => {
    setAppointmentTimeframe(
      appointmentTimeframe === 'upcoming' ? 'past' : 'upcoming',
    );
  };

  const handleFindAvailability = (date: Date | null) => {
    const availability = getAvailability(date);
    if (!availability) return;
    postFindAvailability(availability, {
      onSuccess: (data) => {
        setSelectedTimeSpan(null);
        setTimeSpanList(data);
      },
    });
  };

  const handleFindNextAvailableAppointment = () => {
    const availability = getAvailability(moment().toDate());
    if (!availability) return;
    postFindNextAvailableAppointment(availability, {
      onSuccess: (data) => {
        const selectedTimeSpan = data.find(
          (timeSpan: TypeTimeSpanDTO) => timeSpan.selected === true,
        );
        const appointmentDate = moment(selectedTimeSpan.startAt)
          .startOf('day')
          .toDate();
        setTimeSpanList(data);
        setSelectedTimeSpan(selectedTimeSpan);
        setSelectedDate(appointmentDate);
      },
    });
  };

  useEffect(() => {
    if (selectedAppointmentClientProfile) {
      refetchSquareAppointmentBalance();
      refetchClientAppointments();
    } else {
      setAppointments([]);
      setAppointmentBalance(null);
    }
  }, [selectedAppointmentClientProfile, appointmentTimeframe]);

  const handleCancelAppointment = (appointment: TypeAppointment) => {
    postCancelAppointment(appointment, {
      onSuccess: () => setSnackbar(`Appointment canceled successfully!`),
      onError: () => setSnackbar(`Failed to cancel appointment`, 'error'),
      onSettled: () => {
        resetAppointmentScheduler();
        refetchClientAppointments();
        closeDialog('cancelAppointmentDialog');
      },
    });
  };

  const appointmentHeadlineDetails = (appointment: TypeAppointment) => {
    const appointmentTypeName = appointment?.appointmentType?.name || '';
    const startFormatted = appointment.startAt
      ? moment(appointment.startAt).format('dddd, MMMM D, YYYY h:mma')
      : undefined;
    const withProfile =
      appointment.appointmentCalendar?.name ??
      appointment.appointmentCalendarGroup?.displayName;
    if (!appointmentTypeName) {
      return '';
    } else if (!withProfile) {
      return `${appointmentTypeName}`;
    } else if (!startFormatted) {
      return `${appointmentTypeName} with ${withProfile}`;
    } else {
      return `${appointmentTypeName} with ${withProfile} on ${startFormatted}`;
    }
  };

  const appointmentDetails = (appointment: TypeAppointment) => {
    const startFormatted = moment(appointment.startAt).format('MM/DD hh:mm a');
    const withProfile = appointment.appointmentCalendar?.profile?.name;
    return `${startFormatted} with ${withProfile}`;
  };

  useEffect(() => {
    if (selectedAppointmentClientProfile) {
      refetchClientAppointments();
    } else {
      setAppointments([]);
    }
  }, [selectedAppointmentClientProfile]);

  // useEffect(() => {
  //   handleFindAvailability();
  // }, [selectedDate]);

  return (
    <AppointmentContext.Provider
      value={{
        squareApplicationId,
        squareLocationId,
        timeSpanList,
        appointmentCalendarGroups: appointmentCalendarGroups || [],
        appointmentCalendars: appointmentCalendars || [],
        appointmentTypes: appointmentTypes || [],
        appointments,
        appointmentTimeframe,
        toggleAppointmentTimeframe,
        disableCreateAppointment,
        sufficientBalance,
        createAppointment,
        completeSquareOrder,
        appointmentAction,
        handleCancelAppointment,
        handleFindAvailability,
        handleFindNextAvailableAppointment,
        appointmentHeadlineDetails,
        appointmentDetails,
        selectedAppointmentType,
        setSelectedAppointmentType,
        selectedAppointmentCalendar,
        setSelectedAppointmentCalendar,
        selectedAppointmentCalendarGroup,
        setSelectedAppointmentCalendarGroup,
        selectedDate,
        setSelectedDate,
        selectedTimeSpan,
        setSelectedTimeSpan,
        selectedAppointmentClientProfile,
        setSelectedAppointmentClientProfile,
        editAppointment,
        setEditAppointment,
        cancelAppointment,
        setCancelAppointment,
        createdAppointment,
        setCreatedAppointment,
        createdSquareOrder,
        setCreatedSquareOrder,
        newAppointment,
        resetAppointmentScheduler,
        catalogItems: catalogItems
          ? stableSort<TypeSquareCatalogItem>(catalogItems, {
              property: 'position',
              isDescending: false,
            })
          : [],
        selectedCatalogItem,
        setSelectedCatalogItem,
        appointmentBalance: appointmentBalance || null,
        showCatalogItems,
        setShowCatalogItems,
        showAppointmentTypes,
        setShowAppointmentTypes,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
