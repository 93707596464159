import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { AnnouncementList } from './AnnouncementList';
import { useGetAnnouncements, usePostAnnouncement } from '../../../api';

export const Announcements = () => {
  // useRoleProtectedRoute();
  const [announcementTitle, setAnnouncementTitle] = useState<string>('');
  const { ...queryInfo } = useGetAnnouncements();
  const announcementMutation = usePostAnnouncement();

  const createAnnouncement = () => {
    if (announcementTitle === '') return;
    announcementMutation.mutate({
      announcementTitle: announcementTitle,
    });
    setAnnouncementTitle('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnnouncementTitle(event.target.value);
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="announcementTitle"
              value={announcementTitle}
              label="Search or Add Announcement"
              placeholder="Announcements Title"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              disabled={announcementTitle === ''}
              onClick={createAnnouncement}
            >
              Create Announcement
            </Button>
          </Grid>
        </Grid>
        <AnnouncementList
          announcements={queryInfo.data}
          announcementsAvailable={!queryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
