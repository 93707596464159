import React, { useContext } from 'react';
import { UserAnnouncements } from '../../_common';
import { AuthContext } from '../../../providers';
import { TaskList } from '../../coach';

export const CoachDashboard = () => {
  const { profile } = useContext(AuthContext);

  if (!profile) return <></>;

  return (
    <>
      <UserAnnouncements />
      <TaskList />
    </>
  );
};
