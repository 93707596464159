import React, { useContext } from 'react';
import { AlertDialog } from './AlertDialog';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

type DeleteDiscussionTopicDialogProps = {
  discussionTopicTitle: string;
  usages: number;
  deleteFn: () => void;
};

export const DeleteDiscussionTopicDialog = ({
  discussionTopicTitle,
  usages,
  deleteFn,
}: DeleteDiscussionTopicDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteDiscussionTopicDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Delete ${discussionTopicTitle}?`,
    contentText: `${discussionTopicTitle} is used in ${usages} places.`,
    dismissiveAction: (
      <Button
        color="primary"
        onClick={() => closeDialog('deleteDiscussionTopicDialog')}
      >
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={deleteFn}
      >
        Delete {discussionTopicTitle}
      </Button>
    ),
  };

  return (
    <AlertDialog
      dialogProps={{ open: deleteDiscussionTopicDialog }}
      {...props}
    />
  );
};
