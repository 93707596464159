import React, { useContext, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { LeagueTimeList } from './index';
import { useGetLeagueTimes, usePostLeagueTime } from '../../../api';
import { LeagueContext, ProjectContext } from '../../../providers';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';

export const LeagueTimes = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { league } = useContext(LeagueContext);
  const [leagueTime, setLeagueTime] = useState<Date | null>(null);
  const { data: leagueTimesData, isLoading: leagueTimesLoading } =
    useGetLeagueTimes(Number(league?.id), { enabled: !!league?.id });
  const { mutate: leagueTimeMutation } = usePostLeagueTime();

  const createLeagueTime = () => {
    if (leagueTime === null) return;
    const leagueTimeString = moment(leagueTime).format('hh:mm A');
    leagueTimeMutation(
      {
        time: leagueTimeString,
        league: league,
      },
      {
        onSuccess: () => setSnackbar('New Time Saved'),
      },
    );
    setLeagueTime(null);
  };

  const handleChange = (newValue: Date | null) => {
    if (newValue === null) return;
    setLeagueTime(newValue);
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TimePicker
            label="Search or Add Time"
            value={leagueTime}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            disabled={leagueTime === null}
            onClick={createLeagueTime}
          >
            Create Time
          </Button>
        </Grid>
      </Grid>
      <LeagueTimeList
        leagueTimes={leagueTimesData || []}
        leagueTimesAvailable={!leagueTimesLoading}
      />
    </Box>
  );
};
