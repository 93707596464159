import React, { useContext } from 'react';
import { useGetTimeSlots } from '../../../api';
import { LeagueContext } from '../../../providers';
import { TimeSlotList } from './TimeSlotList';

export const DivisionTimeSlotList = () => {
  const { selectedDivision } = useContext(LeagueContext);

  const { data: timeSlotsData, isLoading: timeSlotsLoading } = useGetTimeSlots(
    selectedDivision?.id!!,
    { enabled: !!selectedDivision, refetchOnWindowFocus: false },
  );

  if (timeSlotsLoading) {
    return <></>;
  }
  return <TimeSlotList timeSlots={timeSlotsData || []} allowEdit={true} />;
};
