import React, { Fragment, useContext, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  TypePracticeAvailability,
  TypePracticeAvailabilityDay,
  TypePracticeDivisionGroup,
  TypePracticeSlotDay,
  TypeTeam,
} from '../../../types';
import {
  ReleasePracticeSlotDayDialog,
  SwapPracticeSlotDayDialog,
} from '../../_common/Dialogs';
import {
  usePostPracticeAvailabilityDaySwap,
  usePostPracticeSlotDay,
} from '../../../api';
import { DialogContext, ProjectContext } from '../../../providers';

type PracticeSlotListProps = {
  team: TypeTeam | null;
  practiceSlotDays: TypePracticeSlotDay[];
  practiceAvailabilities: TypePracticeAvailability[];
  practiceDivisionGroup: TypePracticeDivisionGroup | null;
  isSelectionOpen: boolean;
  hasPracticeAtSameDayTime: (
    _practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ) => boolean;
};
export const PracticeSlotDayList = ({
  team,
  practiceSlotDays,
  practiceAvailabilities,
  practiceDivisionGroup,
  isSelectionOpen,
  hasPracticeAtSameDayTime,
}: PracticeSlotListProps) => {
  const { setSnackbar, isSmallScreen } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);

  const [selectedPracticeSlotDay, setSelectedPracticeSlotDay] =
    useState<TypePracticeSlotDay>();

  const { mutate: postPracticeSlotDay } = usePostPracticeSlotDay();
  const { mutate: postPracticeAvailabilityDaySwap } =
    usePostPracticeAvailabilityDaySwap();

  // const handleReleasePracticeSlotDay = (
  //   practiceSlotDay: TypePracticeSlotDay,
  // ) => {
  //   setSelectedPracticeSlotDay(practiceSlotDay);
  //   openDialog('releasePracticeSlotDayDialog');
  // };

  const handleSwapPracticeSlotDay = (practiceSlotDay: TypePracticeSlotDay) => {
    setSelectedPracticeSlotDay(practiceSlotDay);
    openDialog('swapPracticeSlotDayDialog');
  };

  const resetReleasePracticeSlotDay = (error = false) => {
    setSelectedPracticeSlotDay(undefined);
    closeDialog('releasePracticeSlotDayDialog');
    if (error) {
      setSnackbar('Error releasing practice slot', 'error');
    } else {
      setSnackbar('Practice slot released');
    }
  };

  const resetSwapPracticeSlotDay = (error = false) => {
    setSelectedPracticeSlotDay(undefined);
    closeDialog('swapPracticeSlotDayDialog');
    if (error) {
      setSnackbar('Error swapping practice slot', 'error');
    } else {
      setSnackbar('Practice slot swapped');
    }
  };

  const completeReleasePracticeSlotDay = () => {
    if (selectedPracticeSlotDay && team) {
      selectedPracticeSlotDay.team = undefined;
      postPracticeSlotDay(selectedPracticeSlotDay, {
        onSuccess: () => resetReleasePracticeSlotDay(),
        onError: () => resetReleasePracticeSlotDay(true),
      });
    }
  };

  const completeSwapPracticeSlotDay = (
    practiceAvailabilityDay: TypePracticeAvailabilityDay | null,
  ) => {
    if (selectedPracticeSlotDay && practiceAvailabilityDay && team) {
      practiceAvailabilityDay.claimWithTeam = team;
      postPracticeAvailabilityDaySwap(
        {
          practiceSlotDayId: selectedPracticeSlotDay?.id!!,
          practiceAvailabilityDay: practiceAvailabilityDay,
        },
        {
          onSuccess: () => resetSwapPracticeSlotDay(),
          onError: () => resetSwapPracticeSlotDay(true),
        },
      );
    }
  };

  const handleCloseSwapDialog = () => {
    setSelectedPracticeSlotDay(undefined);
    closeDialog('swapPracticeSlotDayDialog');
  };

  const teamPracticeSlotDaysLarge = practiceSlotDays.length > 0 && (
    <TableContainer>
      <Table style={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Day</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {practiceSlotDays?.map((practiceSlotDay, index) => (
            <TableRow key={index}>
              <TableCell>{practiceSlotDay.leagueField?.name}</TableCell>
              <TableCell>{practiceSlotDay.leagueDay?.day}</TableCell>
              <TableCell>{practiceSlotDay.leagueTime?.time}</TableCell>
              <TableCell>
                <Button
                  sx={{ marginRight: 2 }}
                  variant={'contained'}
                  color={'info'}
                  disabled={!isSelectionOpen}
                  onClick={() => handleSwapPracticeSlotDay(practiceSlotDay)}
                >
                  Swap
                </Button>
                {/*<Button*/}
                {/*  variant={'contained'}*/}
                {/*  color={'error'}*/}
                {/*  onClick={() =>*/}
                {/*    handleReleasePracticeSlotDay(practiceSlotDay)*/}
                {/*  }*/}
                {/*>*/}
                {/*  Release*/}
                {/*</Button>*/}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const teamPracticeSlotDaysSmall = practiceSlotDays.length > 0 && (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        Practice
      </Grid>
      <Grid item xs={3}>
        Actions
      </Grid>
      {practiceSlotDays?.map((practiceSlotDay, index) => (
        <Fragment key={index}>
          <Grid item xs={9}>
            {'Field '}
            {practiceSlotDay.leagueField?.name} -{' '}
            {practiceSlotDay.leagueDay?.day} -{' '}
            {practiceSlotDay.leagueTime?.time}
          </Grid>
          <Grid item xs={3}>
            <Button
              sx={{ marginRight: 2 }}
              variant={'contained'}
              color={'info'}
              disabled={!isSelectionOpen}
              onClick={() => handleSwapPracticeSlotDay(practiceSlotDay)}
            >
              Swap
            </Button>
            {/*<Button*/}
            {/*  variant={'contained'}*/}
            {/*  color={'error'}*/}
            {/*  onClick={() =>*/}
            {/*    handleReleasePracticeSlotDay(practiceSlotDay)*/}
            {/*  }*/}
            {/*>*/}
            {/*  Release*/}
            {/*</Button>*/}
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );

  if (!practiceSlotDays || !team) {
    return <></>;
  }

  return (
    <>
      <Box mt={2} mb={2}>
        <Typography variant={'h6'}>My Team Practices ({team?.name})</Typography>
        <Typography variant={'body1'}>
          Used {practiceSlotDays?.length} of{' '}
          {practiceDivisionGroup?.slotsPerTeam} practice slots
        </Typography>
      </Box>
      {isSmallScreen ? teamPracticeSlotDaysSmall : teamPracticeSlotDaysLarge}
      <ReleasePracticeSlotDayDialog
        practiceSlotDay={selectedPracticeSlotDay}
        releaseFn={completeReleasePracticeSlotDay}
      />
      <SwapPracticeSlotDayDialog
        practiceSlotDay={selectedPracticeSlotDay}
        practiceAvailabilities={practiceAvailabilities}
        hasPracticeAtSameDayTime={hasPracticeAtSameDayTime}
        swapPracticeSlotDay={completeSwapPracticeSlotDay}
        outerHandleCloseDialog={handleCloseSwapDialog}
      />
    </>
  );
};
