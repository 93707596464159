import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMapboxDirections } from '../../../types';
import { LngLat } from 'mapbox-gl';

const fetchMapboxRoute = async (
  coords: LngLat[],
): Promise<TypeMapboxDirections> => {
  const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  const coordsString = coords
    .map((coord) => `${coord.lng},${coord.lat}`)
    .join(';');
  const mapboxUri = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${coordsString}?geometries=geojson&access_token=${mapboxAccessToken}`;
  const res = await axios.get(mapboxUri);
  return res.data;
};

export const PrefetchMapboxRoute = (coords: LngLat[]) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['mapbox-route'],
    () => fetchMapboxRoute(coords),
    {
      staleTime: 5000,
    },
  );
};

export const useGetMapboxRoute = (
  coords: LngLat[],
  options?: UseQueryOptions<
    TypeMapboxDirections,
    AxiosError,
    TypeMapboxDirections,
    any
  >,
) => {
  return useQuery(['mapbox-route'], () => fetchMapboxRoute(coords), options);
};
