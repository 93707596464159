import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTask } from '../../../types';

const fetchTasks = async (): Promise<TypeTask[]> => {
  const res = await axios.get('api/task');
  return res.data;
};

export const PrefetchTask = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['task'], fetchTasks, {
    staleTime: 5000,
  });
};

export const useGetTasks = (
  options?: UseQueryOptions<TypeTask[], AxiosError, TypeTask[], any>,
) => {
  return useQuery('task', fetchTasks, options);
};
