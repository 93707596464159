import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  useCancelLeagueWeekReschedule,
  useConfirmLeagueWeekReschedule,
  useGetLeagueWeekReschedule,
  useGetTimeSlotsByLeagueWeek,
  usePostLeagueWeekReschedule,
} from '../../../api';
import { TimeSlotList } from './TimeSlotList';
import { TypeLeagueWeek, TypeLeagueWeekReschedule } from '../../../types';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  DialogContext,
  LeagueContext,
  ProjectContext,
} from '../../../providers';
import { flexCenterStart } from '../../../data/styles';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { ConfirmRescheduleDialog } from '../../_common';

export const LeagueWeekTimeSlotList = () => {
  const { performingAction, setPerformingAction } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { weeks } = useContext(LeagueContext);
  const [sourceLeagueWeek, setSourceLeagueWeek] =
    useState<TypeLeagueWeek | null>(null);
  const [targetLeagueWeek, setTargetLeagueWeek] =
    useState<TypeLeagueWeek | null>(null);
  const [viewLeagueWeek, setViewLeagueWeek] = useState<TypeLeagueWeek | null>(
    null,
  );
  const [leagueWeekReschedule, setLeagueWeekReschedule] =
    useState<TypeLeagueWeekReschedule | null>(null);
  const [rescheduleStartDate, setRescheduleStartDate] = useState<Date | null>(
    null,
  );

  useEffect(() => {
    if (targetLeagueWeek === null) {
      setTargetLeagueWeek(weeks[0]);
    }
    if (viewLeagueWeek === null) {
      setViewLeagueWeek(weeks[0]);
    }
    if (sourceLeagueWeek === null) {
      setSourceLeagueWeek(weeks[0]);
    }
  }, [weeks]);

  useEffect(() => {
    if (leagueWeekReschedule) {
      setViewLeagueWeek(leagueWeekReschedule.newLeagueWeek || null);
      setSourceLeagueWeek(leagueWeekReschedule.sourceLeagueWeek || null);
    } else if (targetLeagueWeek) {
      setViewLeagueWeek(targetLeagueWeek);
    }

    if (leagueWeekReschedule?.startDate) {
      setRescheduleStartDate(moment(leagueWeekReschedule.startDate).toDate());
    } else {
      setRescheduleStartDate(null);
    }
  }, [leagueWeekReschedule, targetLeagueWeek]);

  useGetLeagueWeekReschedule(targetLeagueWeek?.id!, {
    enabled: !!targetLeagueWeek,
    retry: false,
    refetchOnWindowFocus: false,
    onSettled: (data) => setLeagueWeekReschedule(data || null),
  });

  const { mutate: create } = usePostLeagueWeekReschedule();
  const { mutate: cancel } = useCancelLeagueWeekReschedule();
  const { mutate: confirm } = useConfirmLeagueWeekReschedule();

  const { data: timeSlotsData, isLoading: timeSlotsLoading } =
    useGetTimeSlotsByLeagueWeek(viewLeagueWeek?.id!, {
      enabled: !!viewLeagueWeek,
      refetchOnWindowFocus: false,
    });

  const handleCancel = () =>
    leagueWeekReschedule &&
    cancel(leagueWeekReschedule, {
      onSuccess: () => {
        setLeagueWeekReschedule(null);
        setViewLeagueWeek(targetLeagueWeek);
      },
    });

  const handleConfirmRescheduleDialog = () => {
    if (leagueWeekReschedule) {
      openDialog('confirmRescheduleDialog');
    } else {
      closeDialog('confirmRescheduleDialog');
    }
  };

  const handleConfirm = () => {
    if (leagueWeekReschedule) {
      setPerformingAction(true);
      confirm(leagueWeekReschedule, {
        onSuccess: () => {
          setLeagueWeekReschedule(null);
          setTargetLeagueWeek(null);
          setSourceLeagueWeek(null);
          setRescheduleStartDate(null);
          closeDialog('confirmRescheduleDialog');
        },
        onSettled: () => setPerformingAction(false),
      });
    }
  };

  const handleCreate = () => {
    if (sourceLeagueWeek && targetLeagueWeek && rescheduleStartDate) {
      setPerformingAction(true);
      create(
        {
          week: targetLeagueWeek.week,
          sourceLeagueWeek: sourceLeagueWeek,
          targetLeagueWeek: targetLeagueWeek,
          league: targetLeagueWeek.league,
          startDate: rescheduleStartDate,
          active: true,
        },
        {
          onSuccess: (data) => setLeagueWeekReschedule(data || null),
          onSettled: () => setPerformingAction(false),
        },
      );
    }
  };

  const allowEdit = useMemo(() => {
    return (
      viewLeagueWeek?.week!! === leagueWeekReschedule?.newLeagueWeek?.week!!
    );
  }, [viewLeagueWeek, leagueWeekReschedule]);

  const formattedTargetLeagueWeek = useMemo(
    () =>
      targetLeagueWeek?.startDate &&
      moment(targetLeagueWeek?.startDate).format('dddd, MMM Do'),
    [targetLeagueWeek],
  );

  const formattedSourceLeagueWeek = useMemo(
    () =>
      sourceLeagueWeek?.startDate &&
      moment(sourceLeagueWeek?.startDate).format('dddd, MMM Do'),
    [sourceLeagueWeek],
  );

  if (timeSlotsLoading) {
    return <></>;
  }
  return (
    <Box mt={2}>
      <Grid container spacing={2} style={flexCenterStart}>
        <Grid item xs={2}>
          <Autocomplete
            value={targetLeagueWeek}
            id="setTargetLeagueWeek"
            options={weeks || []}
            getOptionLabel={(option) => `${option.week}`}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => setTargetLeagueWeek(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Target Week"
                placeholder="Target Week"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant={'body1'}>{formattedTargetLeagueWeek}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            value={sourceLeagueWeek}
            id="setSourceLeagueWeek"
            options={weeks || []}
            getOptionLabel={(option) => `${option.week}`}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => {
              setSourceLeagueWeek(newValue);
              setViewLeagueWeek(newValue);
            }}
            disabled={leagueWeekReschedule !== null}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source Week"
                placeholder="Source Week"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant={'body1'}>{formattedSourceLeagueWeek}</Typography>
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            label="New Week"
            value={rescheduleStartDate}
            onChange={(newValue) => setRescheduleStartDate(newValue)}
          />
        </Grid>
        <Grid item xs={6} style={flexCenterStart}>
          {leagueWeekReschedule ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  setViewLeagueWeek(leagueWeekReschedule?.newLeagueWeek!!)
                }
                sx={{ mr: 2 }}
              >
                Show New Week
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  setViewLeagueWeek(leagueWeekReschedule?.sourceLeagueWeek!!)
                }
              >
                Show Source Week
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setViewLeagueWeek(targetLeagueWeek!!)}
                disabled={!targetLeagueWeek}
                sx={{ mr: 2 }}
              >
                Show Target Week
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setViewLeagueWeek(sourceLeagueWeek!!)}
                disabled={!sourceLeagueWeek}
              >
                Show Source Week
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={6} style={flexCenterStart}>
          {leagueWeekReschedule ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmRescheduleDialog}
                sx={{ mr: 2 }}
              >
                Confirm Reschedule
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel Reschedule
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreate}
              disabled={
                performingAction ||
                !sourceLeagueWeek ||
                !targetLeagueWeek ||
                !rescheduleStartDate
              }
            >
              Create Make-up Schedule
            </Button>
          )}
        </Grid>
      </Grid>

      <TimeSlotList timeSlots={timeSlotsData || []} allowEdit={allowEdit} />

      {leagueWeekReschedule && (
        <ConfirmRescheduleDialog
          weekNumber={leagueWeekReschedule.newLeagueWeek?.week!!}
          confirmFn={handleConfirm}
        />
      )}
    </Box>
  );
};
