import React, { useContext } from 'react';
import { AlertDialog } from './AlertDialog';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';
import { TypeOrder } from '../../../types';

type DeleteOrderDialogProps = {
  order: TypeOrder;
  deleteFn: () => void;
};

export const DeleteOrderDialog = ({
  order,
  deleteFn,
}: DeleteOrderDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteOrderDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Delete ${order.profile?.name}?`,
    contentText: 'This will delete the order completely.',
    dismissiveAction: (
      <Button color="primary" onClick={() => closeDialog('deleteOrderDialog')}>
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={deleteFn}
      >
        Delete {order.profile?.name}
      </Button>
    ),
  };

  return <AlertDialog dialogProps={{ open: deleteOrderDialog }} {...props} />;
};
