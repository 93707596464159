import React, { useContext } from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeAnnouncement } from '../../../types';
import { DialogContext } from '../../../providers';
import { DeleteAnnouncementDialog } from '../Dialogs';
import { useDeleteAnnouncement, useGetAnnouncement } from '../../../api';
import { DeleteForever } from '@mui/icons-material';

export const AnnouncementList = ({
  announcements,
  announcementsAvailable,
}: {
  announcements: TypeAnnouncement[] | undefined;
  announcementsAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { mutate: deleteAnnouncement } = useDeleteAnnouncement();
  const [announcementToDelete, setAnnouncementToDelete] =
    React.useState<TypeAnnouncement>();
  const { data: announcementData } = useGetAnnouncement(
    announcementToDelete?.id!!,
    {
      enabled: !!announcementToDelete,
    },
  );

  const handleDeleteAnnouncementDialog = (announcement?: TypeAnnouncement) => {
    if (announcement) {
      setAnnouncementToDelete(announcement);
      openDialog('deleteAnnouncementDialog');
    } else {
      setAnnouncementToDelete(undefined);
      closeDialog('deleteAnnouncementDialog');
    }
  };

  return (
    <>
      <TableContainer hidden={!announcementsAvailable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Announcement</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {announcements?.map((announcement) => (
              <TableRow key={announcement.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/announcement/${announcement.id}`}
                    underline="none"
                  >
                    {announcement.announcementTitle}
                  </Link>
                </TableCell>
                <TableCell>{announcement.announcementTime}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleDeleteAnnouncementDialog(announcement)}
                  >
                    <DeleteForever />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {announcements?.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>No Announcements Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {announcementData && (
        <DeleteAnnouncementDialog
          announcementTitle={announcementData.announcementTitle!!}
          deleteFn={() =>
            deleteAnnouncement(announcementData, {
              onSuccess: () => handleDeleteAnnouncementDialog(),
            })
          }
        />
      )}
    </>
  );
};
