import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  TypeIngredient,
  TypeInstruction,
  TypeMaterialOption,
  TypeRecipe,
} from '../../types';
import {
  useGetLinkedMaterialRecipes,
  useGetRecipe,
  usePostRecipe,
} from '../../api';
import { genericSort } from '../../components';
import { ProjectContext } from '../ProjectProvider';
import { ChefContext } from './ChefProvider';
import { AuthContext } from '../AuthProvider';

type RecipeContextType = {
  recipe: TypeRecipe;
  setRecipe: (_recipe: TypeRecipe) => void;
  updateRecipe: () => void;
  resetRecipe: () => void;
  materialOptionsForRecipe: TypeMaterialOption[];
  linkedMaterialRecipes: TypeRecipe[];
  isRecipeReady: boolean;
  isRecipeValid: boolean;
};

export const RecipeContext = createContext<RecipeContextType>({
  recipe: {},
  setRecipe: (_recipe: TypeRecipe) => {},
  updateRecipe: () => {},
  resetRecipe: () => {},
  materialOptionsForRecipe: [],
  linkedMaterialRecipes: [],
  isRecipeReady: false,
  isRecipeValid: false,
});

export type RecipeProviderProps = {
  recipeId: number;
  children: React.ReactNode;
};

export const RecipeProvider = ({ recipeId, children }: RecipeProviderProps) => {
  const { profile } = useContext(AuthContext);
  const { materialOptions, materialRecipes } = useContext(ChefContext);
  const { setSnackbar } = useContext(ProjectContext);
  const [recipe, setRecipe] = useState<TypeRecipe>({});
  const [linkedMaterialRecipes, setLinkedMaterialRecipes] = useState<
    TypeRecipe[]
  >([]);
  const [materialOptionsForRecipe, setMaterialOptionsForRecipe] = useState<
    TypeMaterialOption[]
  >([]);

  const { mutate: recipeMutation } = usePostRecipe();
  const { refetch: refetchRecipe } = useGetRecipe(recipeId, {
    onSuccess: (data) => processRecipeData(data),
    refetchOnWindowFocus: false,
    enabled: !!recipeId,
  });

  const { refetch: refetchLinkedMaterialRecipes } = useGetLinkedMaterialRecipes(
    Number(recipeId),
    {
      onSuccess: (data) => setLinkedMaterialRecipes(data),
      refetchOnWindowFocus: false,
      enabled: !!recipe?.isMaterial,
    },
  );

  const updateRecipe = () => {
    if (!recipe) return;
    recipeMutation(recipe, {
      onSuccess: () => {
        setSnackbar('Recipe saved successfully');
      },
    });
  };

  const resetRecipe = () => {
    refetchRecipe();
    setSnackbar('Recipe reset successfully');
  };

  const processRecipeData = (recipeData: TypeRecipe) => {
    if (!recipeData) return;
    const ingredientList = (recipeData.ingredientList || [])
      .slice()
      .sort((a, b) =>
        genericSort<TypeIngredient>(a, b, {
          property: 'position',
          isDescending: false,
        }),
      );
    const instructionList = (recipeData.instructionList || [])
      .slice()
      .sort((a, b) =>
        genericSort<TypeInstruction>(a, b, {
          property: 'position',
          isDescending: false,
        }),
      );
    setRecipe({
      ...recipeData,
      ingredientList: ingredientList,
      instructionList: instructionList,
    });
  };

  useEffect(() => {
    refetchRecipe();
    refetchLinkedMaterialRecipes();
  }, [profile?.organizationContext]);

  useEffect(() => {
    if (materialRecipes && materialOptions && recipe) {
      if (!recipe?.isMaterial) {
        const options: TypeMaterialOption[] = materialRecipes
          .filter((recipe) => recipe?.id !== recipeId)
          .map((recipe) => {
            return {
              id: recipe.id,
              name: recipe.recipeTitle,
              recipe: recipe,
            };
          });
        options.push(...materialOptions);
        setMaterialOptionsForRecipe(options);
      } else {
        setMaterialOptionsForRecipe(materialOptions);
      }
    }
  }, [recipe, materialRecipes, materialOptions]);

  const isRecipeValid = useMemo(() => {
    if (!recipe) return false;
    const ingredientList = recipe?.ingredientList || [];
    return ingredientList.every(
      (ingredient) => ingredient.valid === undefined || ingredient.valid,
    );
  }, [recipe]);

  return (
    <RecipeContext.Provider
      value={{
        recipe,
        setRecipe,
        updateRecipe,
        resetRecipe,
        materialOptionsForRecipe,
        linkedMaterialRecipes,
        isRecipeReady: !!recipe,
        isRecipeValid: isRecipeValid,
      }}
    >
      {children}
    </RecipeContext.Provider>
  );
};
