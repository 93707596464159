import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeMatch, TypeMatchSchedule } from '../../../types';

export type MatchListProps = {
  matches: TypeMatch[];
  hideColumn?: TypeMatchSchedule;
};

export const MatchList = ({ matches }: MatchListProps) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Week</TableCell>
              <TableCell>Division</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Field</TableCell>
              <TableCell>Team 1</TableCell>
              <TableCell>Team 2</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matches?.map((match) => (
              <TableRow key={`match-${match.id}`}>
                <TableCell>{match.timeSlot?.leagueWeek?.week}</TableCell>
                <TableCell>{match.timeSlot?.division?.name}</TableCell>
                <TableCell>{match.timeSlot?.leagueDate?.date}</TableCell>
                <TableCell>{match.timeSlot?.leagueTime?.time}</TableCell>
                <TableCell>{match.timeSlot?.leagueField?.name}</TableCell>
                <TableCell>
                  {match.team1?.position} - {match.team1?.headCoach?.name}
                </TableCell>
                <TableCell>
                  {match.team2?.position} - {match.team2?.headCoach?.name}
                </TableCell>
              </TableRow>
            ))}
            {matches?.length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>No Matches Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
