import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { LeagueContext, ProjectContext } from '../../../providers';
import { Save } from '@mui/icons-material';
import { usePostLeague } from '../../../api';
import moment from 'moment/moment';
import { Loader } from '../../_common';
import { DateTimePicker } from '@mui/x-date-pickers';

export const LeagueDetails = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { isLeagueReady, league, weeks } = useContext(LeagueContext);
  const [leagueName, setLeagueName] = useState<string | null>(null);
  const [leagueMessage, setLeagueMessage] = useState<string | null>(null);
  const [leagueWeekNumber, setLeagueWeekNumber] = useState<number>(0);
  const [selectionOpenDateTime, setSelectionOpenDateTime] =
    useState<Date | null>(null);
  const [selectionCloseDateTime, setSelectionCloseDateTime] =
    useState<Date | null>(null);
  const { mutate: leagueMutation } = usePostLeague();

  useEffect(() => {
    console.log('weeks', weeks);
    if (weeks.length > 0) {
      setLeagueWeekNumber(weeks.length);
    }
  }, [weeks]);

  const handleSaveLeague = () =>
    leagueMutation(
      {
        ...league,
        message: leagueMessage || '',
        selectionOpenDateTime: selectionOpenDateTime
          ? moment(selectionOpenDateTime).format('yyyy-MM-DDTHH:mm:ss')
          : undefined,
        selectionCloseDateTime: selectionCloseDateTime
          ? moment(selectionCloseDateTime).format('yyyy-MM-DDTHH:mm:ss')
          : undefined,
        weeks: leagueWeekNumber,
      },
      {
        onSuccess: () => setSnackbar('League Details Updated Successfully'),
      },
    );

  useEffect(() => {
    if (league) {
      setLeagueName(league.name || '');
      setLeagueMessage(league.message || '');
      if (league.selectionOpenDateTime) {
        setSelectionOpenDateTime(moment(league.selectionOpenDateTime).toDate());
      }
      if (league.selectionCloseDateTime) {
        setSelectionCloseDateTime(
          moment(league.selectionCloseDateTime).toDate(),
        );
      }
    }
  }, [league]);

  if (!isLeagueReady) {
    return <Loader />;
  }

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            value={leagueName || ''}
            label="League Name"
            placeholder="League Name"
            onChange={(event) => setLeagueName(event.target.value as string)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            label="Selection Open Date/Time"
            value={selectionOpenDateTime}
            onChange={(newValue) => {
              setSelectionOpenDateTime(newValue);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            label="Selection Close Date/Time"
            value={selectionCloseDateTime}
            onChange={(newValue) => {
              setSelectionCloseDateTime(newValue);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            type={'number'}
            id="leagueWeekNumber"
            value={leagueWeekNumber}
            label="League Weeks"
            placeholder="Weeks"
            onChange={(event) =>
              setLeagueWeekNumber(Number(event.target.value))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            id={'message'}
            label="League Message"
            value={leagueMessage || ''}
            placeholder="League Message"
            inputProps={{ maxLength: 4000 }}
            multiline
            maxRows={20}
            onChange={(event) => setLeagueMessage(event.target.value as string)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={handleSaveLeague}
            endIcon={<Save />}
          >
            Save League Details
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
