import React from 'react';
import moment from 'moment';
import { Alert } from '@mui/material';

type PracticeSelectionOpenProps = {
  momentOpenTime?: moment.Moment;
  momentCloseTime?: moment.Moment;
  isSelectionOpen: boolean;
};

export const PracticeSelectionOpen = ({
  momentOpenTime,
  momentCloseTime,
  isSelectionOpen,
}: PracticeSelectionOpenProps) => {
  const selectionOpenString = isSelectionOpen ? 'open' : 'closed';

  const startDateString = momentOpenTime?.isValid()
    ? `Start date: ${momentOpenTime.format('MMM Do h:mm a')}`
    : '';
  const endDateString = momentCloseTime?.isValid()
    ? `End date: ${momentCloseTime.format('MMM Do h:mm a')}`
    : '';

  return (
    <Alert severity="info">
      {`Practice slot selection is ${selectionOpenString}. ${startDateString} ${endDateString}`}
    </Alert>
  );
};
