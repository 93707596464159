import React, { useContext } from 'react';
import { Button, Grid, ListItem, Paper, TextField } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import { TypeInstruction } from '../../../types';
import { RecipeContext } from '../../../providers';
import { flexCenterStart } from '../../../data/styles';
import { useSortable } from '@dnd-kit/sortable';
import { UniqueIdentifier } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

type InstructionProps = {
  instruction: TypeInstruction;
  instructionIndex: number;
};

export const Instruction = ({
  instruction,
  instructionIndex,
}: InstructionProps) => {
  const { recipe, setRecipe } = useContext(RecipeContext);

  const handleInstructionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    instructionIndex: number,
  ) => {
    const eventValue = event.target.value;
    console.log('eventValue', eventValue);
    const updatedInstructionList = [...(recipe.instructionList ?? [])];
    updatedInstructionList[instructionIndex].instructionText = eventValue;
    setRecipe({
      ...recipe,
      instructionList: updatedInstructionList,
    });
  };

  const removeRowInstructionList = (instructionIndex: number) => {
    setRecipe({
      ...recipe,
      instructionList: recipe?.instructionList?.filter(
        (item, index) => index !== instructionIndex,
      ),
    });
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: instruction.sortableId as UniqueIdentifier,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Paper
      elevation={3}
      sx={{ width: '100%', marginBottom: 1 }}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <ListItem
        sx={{
          pb: 0,
          pt: 0,
        }}
      >
        <Grid container spacing={2} mb={1} mt={1}>
          <Grid item xs={1} style={flexCenterStart}>
            <DragIndicatorIcon {...listeners} style={{ cursor: 'grab' }} />
          </Grid>
          <Grid item xs={11}>
            <TextField
              fullWidth
              variant="outlined"
              label="Instruction"
              value={instruction.instructionText || ''}
              placeholder="Instruction"
              inputProps={{ maxLength: 1000 }}
              multiline
              maxRows={5}
              onChange={(event) =>
                handleInstructionChange(event, instructionIndex)
              }
            />
          </Grid>
          <Grid item xs={12} style={flexCenterStart}>
            <Button
              variant="outlined"
              onClick={() => removeRowInstructionList(instructionIndex)}
            >
              <DeleteForever />
            </Button>
          </Grid>
        </Grid>
      </ListItem>
    </Paper>
  );
};
