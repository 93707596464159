import React, { useMemo, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import {
  useGetOrgInvitesByOrganization,
  useGetProfile,
  useGetRoles,
  usePostOrgInvite,
} from '../../../api';
import { OrgInviteList } from './OrgInviteList';
import Autocomplete from '@mui/material/Autocomplete';
import { TypeOrgInvite, TypeRole } from '../../../types';
import { OrganizationsDropdown } from './OrganizationsDropdown';
import { Loader } from '../Loader';
import { genericSearch, genericSort } from '../Utils';

export const OrgInvites = () => {
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
  } = useGetProfile();
  const {
    data: orgInvitesData,
    isLoading: orgInvitesLoading,
    error: orgInvitesError,
  } = useGetOrgInvitesByOrganization(profileData?.organizationContext?.id || 0);
  const {
    data: rolesData,
    isLoading: rolesLoading,
    error: rolesError,
  } = useGetRoles();
  const orgInviteMutation = usePostOrgInvite();
  const [email, setEmail] = useState<string>('');
  const [roles, setRoles] = useState<TypeRole[]>([]);

  const disableCreateOrgInvite =
    email === '' ||
    roles.length === 0 ||
    profileData?.organizationContext === undefined;

  const createOrgInvite = () => {
    if (disableCreateOrgInvite) return;
    orgInviteMutation.mutate({
      email: email,
      authorities: roles,
      organization: profileData?.organizationContext,
    });
    setEmail('');
  };

  const filteredOrgInvites = useMemo(() => {
    if (!orgInvitesData || (email === '' && roles.length === 0))
      return orgInvitesData || [];
    const queryObject: object = {};
    if (email) queryObject['email'] = email;
    if (roles) queryObject['authorities'] = roles;
    console.log(queryObject);
    return orgInvitesData
      .filter((orgInvite) =>
        genericSearch<TypeOrgInvite>(orgInvite, queryObject),
      )
      .sort((a, b) =>
        genericSort<TypeOrgInvite>(a, b, {
          property: 'email',
          isDescending: false,
        }),
      );
  }, [orgInvitesData, email, roles]);

  if (
    orgInvitesLoading ||
    orgInvitesError ||
    rolesLoading ||
    rolesError ||
    profileLoading ||
    profileError
  ) {
    return <Loader />;
  }

  return (
    <Box m={2}>
      <Box mb={2}>
        <OrganizationsDropdown
          organization={profileData?.organizationContext}
        />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              variant="outlined"
              id="email"
              value={email}
              label="Email"
              placeholder="Email"
              onChange={(event) => setEmail(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              multiple
              value={roles}
              id="roles"
              options={rolesData || []}
              getOptionLabel={(option) => option.authority || ''}
              filterSelectedOptions
              onChange={(_event, newValue) => setRoles(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Roles" placeholder="Roles" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              disabled={disableCreateOrgInvite}
              onClick={createOrgInvite}
            >
              Create Org Invite
            </Button>
          </Grid>
        </Grid>
      </Box>
      <OrgInviteList
        orgInvites={filteredOrgInvites}
        orgInvitesAvailable={!orgInvitesLoading && !orgInvitesError}
      />
    </Box>
  );
};
