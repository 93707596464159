import React, { useContext, useEffect } from 'react';
import { AppointmentContext, ProjectContext } from '../../../../providers';
import { DateCalendar } from '@mui/x-date-pickers';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';

export const SelectDate = () => {
  const { performingAction } = useContext(ProjectContext);
  const {
    selectedAppointmentClientProfile,
    timeSpanList,
    disableCreateAppointment,
    sufficientBalance,
    createAppointment,
    handleFindAvailability,
    handleFindNextAvailableAppointment,
    appointmentAction,
    selectedDate,
    setSelectedDate,
    selectedTimeSpan,
    setSelectedTimeSpan,
    setShowCatalogItems,
    appointmentBalance,
  } = useContext(AppointmentContext);

  const disableDates = (date: Date) =>
    moment(date).isSameOrBefore(moment().startOf('day'));

  useEffect(() => {
    if (selectedDate == null) {
      const defaultDate = moment().startOf('day').add(2, 'days').toDate();
      setSelectedDate(defaultDate);
      handleFindAvailability(defaultDate);
    }
  }, []);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    handleFindAvailability(date);
  };

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <Typography variant="h6">Select Date & Time</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={6}>
                <DateCalendar
                  value={selectedDate}
                  onChange={handleDateChange}
                  shouldDisableDate={disableDates}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  {moment(selectedDate).format('dddd, MMMM D, YYYY')}
                </Typography>
                <Typography variant="body1">
                  Remaining Balance:{' '}
                  {appointmentBalance?.appointmentHoursRemaining} hour(s)
                </Typography>
                {selectedDate &&
                  timeSpanList.map((timeSpan, index) => (
                    <Button
                      key={`timeSpan-${index}`}
                      size={'large'}
                      sx={{ margin: 1 }}
                      variant={
                        selectedTimeSpan === timeSpan ? 'contained' : 'outlined'
                      }
                      color={'primary'}
                      onClick={() => setSelectedTimeSpan(timeSpan)}
                    >
                      {moment(timeSpan.startAt).format('hh:mm a')}
                    </Button>
                  ))}
                <Button
                  size={'large'}
                  sx={{ margin: 1 }}
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => handleFindNextAvailableAppointment()}
                >
                  Find Next Available
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              sx={{ margin: 1 }}
              variant="contained"
              color="primary"
              onClick={() => createAppointment()}
              disabled={
                disableCreateAppointment ||
                !sufficientBalance ||
                performingAction
              }
            >
              {appointmentAction} Appointment
            </Button>
            <Button
              sx={{ margin: 1 }}
              variant="contained"
              color="primary"
              onClick={() => {
                setShowCatalogItems(true);
                console.log('Add Time');
              }}
              disabled={!selectedAppointmentClientProfile || performingAction}
            >
              Add Appointment Balance
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
