import React, { useContext, useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useDeleteTimeSlot, usePostTimeSlot } from '../../../api';
import { LeagueContext, ProjectContext } from '../../../providers';
import Autocomplete from '@mui/material/Autocomplete';
import {
  TypeLeagueDate,
  TypeLeagueField,
  TypeLeagueTime,
  TypeTimeSlot,
} from '../../../types';
import { Check, DeleteForever, Edit, Restore } from '@mui/icons-material';

export type TimeSlotListProps = {
  timeSlots: TypeTimeSlot[];
  allowEdit?: boolean;
};

export const TimeSlotList = ({ timeSlots, allowEdit }: TimeSlotListProps) => {
  const { setSnackbar } = useContext(ProjectContext);
  const { league } = useContext(LeagueContext);
  const { fields, dates, times, isLeagueReady } = useContext(LeagueContext);

  const { mutate: updateTimeSlot } = usePostTimeSlot();
  const { mutate: deleteTimeSlot } = useDeleteTimeSlot();

  const [activeTimeSlot, setActiveTimeSlot] = useState<TypeTimeSlot | null>(
    null,
  );
  const [editLeagueField, setEditLeagueField] =
    useState<TypeLeagueField | null>(null);
  const [editLeagueDate, setEditLeagueDate] = useState<TypeLeagueDate | null>(
    null,
  );
  const [editLeagueTime, setEditLeagueTime] = useState<TypeLeagueTime | null>(
    null,
  );

  const handleEdit = (timeSlot?: TypeTimeSlot) => {
    if (timeSlot) {
      setEditLeagueField(timeSlot?.leagueField || null);
      setEditLeagueDate(timeSlot?.leagueDate || null);
      setEditLeagueTime(timeSlot?.leagueTime || null);
      setActiveTimeSlot(timeSlot);
    } else {
      setEditLeagueField(null);
      setEditLeagueDate(null);
      setEditLeagueTime(null);
      setActiveTimeSlot(null);
    }
  };

  const handleSave = () => {
    if (activeTimeSlot) {
      updateTimeSlot(
        {
          id: activeTimeSlot.id,
          division: activeTimeSlot.division,
          leagueWeek: activeTimeSlot.leagueWeek,
          leagueField: editLeagueField || undefined,
          leagueDate: editLeagueDate || undefined,
          leagueTime: editLeagueTime || undefined,
        },
        {
          onSuccess: () => {
            setSnackbar('Update TimeSlot');
            handleEdit();
          },
        },
      );
    }
  };

  if (!isLeagueReady) {
    return <></>;
  }

  return (
    <>
      <TableContainer>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Week</TableCell>
              <TableCell>Division</TableCell>
              <TableCell>Field</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              {allowEdit && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {timeSlots?.map((timeSlot) => (
              <TableRow key={timeSlot.id}>
                <TableCell>{timeSlot.leagueWeek?.week}</TableCell>
                <TableCell>{timeSlot.division?.name}</TableCell>
                <TableCell>
                  {activeTimeSlot?.id === timeSlot?.id ? (
                    <Autocomplete
                      value={editLeagueField}
                      id="editLeagueField"
                      options={fields || []}
                      getOptionLabel={(option) => option.name || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      onChange={(_event, newValue) =>
                        setEditLeagueField(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Field"
                          placeholder="Field"
                        />
                      )}
                    />
                  ) : (
                    timeSlot?.leagueField?.name
                  )}
                </TableCell>
                <TableCell>
                  {activeTimeSlot?.id === timeSlot?.id ? (
                    <Autocomplete
                      value={editLeagueDate}
                      id="editLeagueDate"
                      options={dates || []}
                      getOptionLabel={(option) => option.date || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      onChange={(_event, newValue) =>
                        setEditLeagueDate(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Date"
                          placeholder="Date"
                        />
                      )}
                    />
                  ) : (
                    timeSlot?.leagueDate?.date
                  )}
                </TableCell>
                <TableCell>
                  {activeTimeSlot?.id === timeSlot?.id ? (
                    <Autocomplete
                      value={editLeagueTime}
                      id="editLeagueTime"
                      options={times || []}
                      getOptionLabel={(option) => option.time || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      onChange={(_event, newValue) =>
                        setEditLeagueTime(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Time"
                          placeholder="Time"
                        />
                      )}
                    />
                  ) : (
                    timeSlot?.leagueTime?.time
                  )}
                </TableCell>
                {allowEdit && (
                  <TableCell>
                    {activeTimeSlot?.id === timeSlot?.id ? (
                      <>
                        <IconButton onClick={handleSave}>
                          <Check />
                        </IconButton>
                        <IconButton onClick={() => handleEdit()}>
                          <Restore />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton onClick={() => handleEdit(timeSlot)}>
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => deleteTimeSlot(timeSlot)}
                          disabled={league?.matchesGenerated}
                        >
                          <DeleteForever />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
            {timeSlots?.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>No Time Slots Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
