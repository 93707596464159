import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { Clear, MoreTime } from '@mui/icons-material';

interface DurationInputProps {
  label: string;
  duration: number | null;
  onDurationChange: (_duration: number | null) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const DurationInput: React.FC<DurationInputProps> = ({
  label,
  duration,
  onDurationChange,
  inputRef,
}) => {
  const [days, setDays] = useState(duration ? Math.floor(duration / 86400) : 0);
  const [hours, setHours] = useState(
    duration ? Math.floor((duration % 86400) / 3600) : 0,
  );
  const [minutes, setMinutes] = useState(
    duration ? Math.floor((duration % 3600) / 60) : 0,
  );
  const [showDuration, setShowDuration] = useState<boolean>(duration !== null);

  useEffect(() => {
    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60;
    if (showDuration) {
      onDurationChange(totalSeconds);
    } else {
      onDurationChange(null);
    }
  }, [days, hours, minutes, showDuration]);

  const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) || 0;
    setDays(value);
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) || 0;
    setHours(value);
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) || 0;
    setMinutes(value);
  };

  return showDuration ? (
    <Box display="flex" alignItems="flex-start" flexDirection="column" gap={2}>
      <Typography variant="subtitle1">{label}</Typography>
      <Box display="flex" alignItems="center" gap={2}>
        <TextField
          label="Days"
          type="number"
          value={days}
          onChange={handleDaysChange}
          InputProps={{ inputProps: { min: 0 } }}
        />
        <TextField
          label="Hours"
          type="number"
          value={hours}
          onChange={handleHoursChange}
          InputProps={{ inputProps: { min: 0 } }}
        />
        <TextField
          label="Minutes"
          type="number"
          value={minutes}
          onChange={handleMinutesChange}
          inputRef={inputRef}
          InputProps={{ inputProps: { min: 0 } }}
        />
        <IconButton
          onClick={() => {
            setShowDuration(false);
          }}
        >
          <Clear />
        </IconButton>
      </Box>
    </Box>
  ) : (
    <Button
      variant="text"
      onClick={() => setShowDuration(true)}
      startIcon={<MoreTime />}
    >
      Add {label}
    </Button>
  );
};
