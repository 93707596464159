import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { TypeTab } from '../../types';

export interface TabViewProps {
  tabs: TypeTab[];
  title?: string;
  onSelectTab?: (_selectedTab: number) => void;
}

export const TabView = ({ tabs, title, onSelectTab }: TabViewProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Box>
      {title && (
        <Box mt={2}>
          <Typography variant="h4">{title}</Typography>
        </Box>
      )}
      <Box mt={2}>
        <Tabs
          style={{ overflow: 'initial', minHeight: 'initial' }}
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(event, value) => {
            setSelectedTab(value);
            if (onSelectTab) {
              onSelectTab(value);
            }
          }}
        >
          {tabs.map((tab, index) => {
            return <Tab key={index} icon={tab.icon} label={tab.name} />;
          })}
        </Tabs>
        {tabs.map(
          (tab, index) =>
            index === selectedTab && <tab.component key={index} />,
        )}
      </Box>
    </Box>
  );
};
