import React, { useContext } from 'react';
import { AlertDialog } from './AlertDialog';
import { ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

type DeleteDialogProps = {
  deleteTitle: string;
  contentText: string;
  deleteFn: () => void;
  closeFn: () => void;
  open: boolean;
};

export const DeleteDialog = ({
  deleteTitle,
  contentText,
  deleteFn,
  closeFn,
  open,
}: DeleteDialogProps) => {
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Delete ${deleteTitle}?`,
    contentText: contentText,
    dismissiveAction: (
      <Button color="primary" variant="outlined" onClick={closeFn}>
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="secondary"
        variant="contained"
        disabled={performingAction}
        onClick={deleteFn}
      >
        Delete {deleteTitle}
      </Button>
    ),
  };

  return <AlertDialog dialogProps={{ open }} {...props} />;
};
