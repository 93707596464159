import React, { useContext } from 'react';
import { AlertDialog } from './AlertDialog';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

type DeleteMeasurementDialogProps = {
  measurementName: string;
  usages: number;
  deleteFn: () => void;
};

export const DeleteMeasurementDialog = ({
  measurementName,
  usages,
  deleteFn,
}: DeleteMeasurementDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteMeasurementDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Delete ${measurementName}?`,
    contentText: `${measurementName} is used in ${usages} recipes.`,
    dismissiveAction: (
      <Button
        color="primary"
        onClick={() => closeDialog('deleteMeasurementDialog')}
      >
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={deleteFn}
      >
        Delete {measurementName}
      </Button>
    ),
  };

  return (
    <AlertDialog dialogProps={{ open: deleteMeasurementDialog }} {...props} />
  );
};
