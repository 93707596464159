import React, { useContext } from 'react';

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import { DialogContext, ProjectContext } from '../../../providers';
import { Replay, Save } from '@mui/icons-material';

type DocumentDialogProps = {
  title?: string;
  saveFn: () => void;
  resetFn: () => void;
  children: React.ReactNode;
};

export const DocumentDialog = ({
  title,
  saveFn,
  resetFn,
  children,
}: DocumentDialogProps) => {
  const { performingAction } = useContext(ProjectContext);
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { documentDialog } = dialogs;

  return (
    <Dialog open={documentDialog} fullScreen>
      <Container>
        {title && (
          <DialogTitle sx={{ paddingLeft: 0, paddingRight: 0 }}>
            {title}
          </DialogTitle>
        )}

        {children}

        <DialogActions sx={{ paddingLeft: 0, paddingRight: 0 }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => closeDialog('documentDialog')}
          >
            Close
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={resetFn}
            endIcon={<Replay />}
          >
            Reset
          </Button>
          <Button
            color="primary"
            disabled={performingAction}
            variant="contained"
            onClick={saveFn}
            endIcon={<Save />}
          >
            Save & Close
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
