import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeAnnouncement } from '../../../types';

const fetchAnnouncement = async (
  announcementId: number,
): Promise<TypeAnnouncement> => {
  const res = await axios.get(`api/announcement/${announcementId}`);
  return res.data;
};

export const PrefetchAnnouncement = (announcementId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['announcement', announcementId],
    () => fetchAnnouncement(announcementId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetAnnouncement = (
  announcementId: number,
  options?: UseQueryOptions<
    TypeAnnouncement,
    AxiosError,
    TypeAnnouncement,
    any
  >,
) => {
  return useQuery(
    ['announcement', announcementId],
    () => fetchAnnouncement(announcementId),
    options,
  );
};
