import React, { useContext, useState } from 'react';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TypeDivision, TypeImage } from '../../../types';
import { Check, DeleteForever, Edit, Restore } from '@mui/icons-material';
import { useDeleteDivision, usePostDivision, usePostImage } from '../../../api';
import {
  DialogContext,
  LeagueContext,
  ProjectContext,
} from '../../../providers';
import {
  CloudinaryUpload,
  DeleteDivisionDialog,
  LinkView,
} from '../../_common';

export const DivisionList = () => {
  const { cld } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { league, divisions } = useContext(LeagueContext);
  const [editDivision, setEditDivision] = useState<string>('');
  const [editMapImage, setEditMapImage] = useState<TypeImage | undefined>();
  const [activeDivision, setActiveDivision] = useState<TypeDivision>();
  const [divisionToDelete, setDivisionToDelete] = useState<TypeDivision>();
  const { mutate: updateDivision } = usePostDivision();
  const { mutate: deleteDivision } = useDeleteDivision();
  const { mutate: imageMutation } = usePostImage();
  // const { mutate: deleteDivision } = useDeleteDivision();
  const handleEdit = (division: TypeDivision | null = null) => {
    if (division) {
      setEditDivision(division?.name || '');
      setEditMapImage(division?.mapImage);
      setActiveDivision(division);
    } else {
      setEditDivision('');
      setEditMapImage(undefined);
      setActiveDivision(undefined);
    }
  };

  const handleSave = () => {
    if (activeDivision) {
      updateDivision(
        {
          id: activeDivision.id,
          name: editDivision,
          league: activeDivision.league,
          mapImage: editMapImage,
        },
        {
          onSuccess: () => handleEdit(),
        },
      );
    }
  };

  const handleDeleteDivisionDialog = (division?: TypeDivision) => {
    if (division) {
      setDivisionToDelete(division);
      openDialog('deleteDivisionDialog');
    } else {
      setDivisionToDelete(undefined);
      closeDialog('deleteDivisionDialog');
    }
  };

  const handleUpload = (imageUrl: string) => {
    imageMutation(
      { imageUrl: imageUrl },
      { onSuccess: (data) => setEditMapImage(data) },
    );
  };

  if (!cld) {
    return <></>;
  }
  return (
    <>
      <TableContainer>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Division</TableCell>
              <TableCell>Map</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {divisions?.map((division) => (
              <TableRow key={division.id}>
                <TableCell>
                  {activeDivision?.id === division?.id ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={`week-${division.id}`}
                      value={editDivision}
                      label="Division"
                      type={'text'}
                      onChange={(event) => setEditDivision(event.target.value)}
                    />
                  ) : (
                    division?.name
                  )}
                </TableCell>
                <TableCell>
                  {activeDivision?.id === division?.id &&
                    (editMapImage ? (
                      <Button
                        color={'secondary'}
                        onClick={() => setEditMapImage(undefined)}
                        sx={{ marginRight: 2 }}
                      >
                        Remove
                      </Button>
                    ) : (
                      <CloudinaryUpload onImageUpload={handleUpload} />
                    ))}
                  {activeDivision?.id === division?.id ? (
                    <LinkView
                      link={editMapImage?.imageUrl || ''}
                      title={'View Field Map'}
                      isImage={true}
                    />
                  ) : (
                    <LinkView
                      link={division?.mapImage?.imageUrl}
                      title={'View Field Map'}
                      isImage={true}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {activeDivision?.id === division?.id ? (
                    <>
                      <IconButton onClick={handleSave}>
                        <Check />
                      </IconButton>
                      <IconButton onClick={() => handleEdit()}>
                        <Restore />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEdit(division)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteDivisionDialog(division)}
                        disabled={league?.matchesGenerated}
                      >
                        <DeleteForever />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {divisions?.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>No Divisions Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {divisionToDelete && (
        <DeleteDivisionDialog
          deleteTitle={divisionToDelete?.name!!}
          contentText={`This will delete ${divisionToDelete?.name}, its teams, practices, assigned coaches, and time slots.`}
          deleteFn={() =>
            deleteDivision(divisionToDelete, {
              onSuccess: () => handleDeleteDivisionDialog(),
            })
          }
        />
      )}
    </>
  );
};
