import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeDocument } from '../../../types';

const fetchDocuments = async (): Promise<TypeDocument[]> => {
  const res = await axios.get('api/document');
  return res.data;
};

export const PrefetchDocument = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['document'], fetchDocuments, {
    staleTime: 5000,
  });
};

export const useGetDocuments = (
  options?: UseQueryOptions<TypeDocument[], AxiosError, TypeDocument[], any>,
) => {
  return useQuery('document', fetchDocuments, options);
};
