import React, { useContext } from 'react';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';
import { TypePracticeSlotDay } from '../../../types';
import { AlertDialog } from './AlertDialog';

type ReleasePracticeSlotDayProps = {
  practiceSlotDay?: TypePracticeSlotDay;
  releaseFn: () => void;
};

export const ReleasePracticeSlotDayDialog = ({
  practiceSlotDay,
  releaseFn,
}: ReleasePracticeSlotDayProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { releasePracticeSlotDayDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const dayTimeField = `${practiceSlotDay?.leagueDay?.day} ${practiceSlotDay?.leagueTime?.time} on ${practiceSlotDay?.leagueField?.name}`;

  const props = {
    title: `Release ${dayTimeField}?`,
    contentText: `${dayTimeField} will no longer be reserved.`,
    dismissiveAction: (
      <Button
        color="primary"
        onClick={() => closeDialog('releasePracticeSlotDayDialog')}
      >
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={releaseFn}
      >
        Release Practice Time
      </Button>
    ),
  };

  if (!practiceSlotDay) return <></>;

  return (
    <AlertDialog
      dialogProps={{ open: releasePracticeSlotDayDialog }}
      {...props}
    />
  );
};
