import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { TypeAnnouncement } from '../../../types';
import { useGetAnnouncement, usePostAnnouncement } from '../../../api';
import { useParams } from 'react-router-dom';

export const Announcement = () => {
  const { announcementId } = useParams();
  const [announcement, setAnnouncement] = useState<TypeAnnouncement>({});
  useGetAnnouncement(Number(announcementId), {
    onSuccess: (data) => setAnnouncement(data),
    refetchOnWindowFocus: false,
  });
  const announcementMutation = usePostAnnouncement();

  const updateAnnouncement = () => {
    announcementMutation.mutate(announcement);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAnnouncement(
      Object.assign({}, announcement, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="announcementTitle"
              value={announcement?.announcementTitle || ''}
              label="Enter Announcement Title"
              placeholder="Announcement Title"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updateAnnouncement}>
              Update Announcement
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={10}
              variant="outlined"
              id="announcementNotes"
              value={announcement?.announcementNotes || ''}
              label="Enter Announcement Notes"
              placeholder="Announcement Notes"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
