import { ISortBy } from './ISorter';
import { genericTypeCheck } from './genericTypeCheck';

// comparator function for any property within type T
// works for: strings, numbers, and Dates (and is typed to accept only properties which are those types)
// could be extended for other types but would need some custom comparison function here
export const genericSort = <T extends Record<string, any>>(
  objectA: T,
  objectB: T,
  sorter: ISortBy<T>,
) => {
  const result = () => {
    const aValue = objectA[sorter.property];
    const bValue = objectB[sorter.property];

    if (aValue === undefined && bValue === undefined) return 0;
    if (aValue === undefined) return 1;
    if (bValue === undefined) return -1;
    if (Array.isArray(aValue) && Array.isArray(bValue)) {
      return aValue[0] > bValue[0] ? 1 : -1;
    }
    if (
      genericTypeCheck(aValue, 'string') &&
      genericTypeCheck(bValue, 'string')
    ) {
      console.log('compare string', aValue, bValue, typeof aValue);
      return aValue.localeCompare(bValue);
    }
    if (
      genericTypeCheck(aValue, 'number') &&
      genericTypeCheck(bValue, 'number')
    ) {
      console.log('compare number', aValue, bValue);
      return aValue - bValue;
    }
    return 0;
  };

  return sorter.isDescending ? result() * -1 : result();
};
