import React, { useContext } from 'react';
import {
  DocumentList,
  EditTaskCard,
  TabView,
  TaskList,
  WorkflowList,
} from '../../index';
import { PlannerContext, PlannerProvider } from '../../../providers';
import { Container } from '@mui/material';
import { TagsAndFilters } from './';
import { PlannerSearch } from './PlannerSearch';
import {
  Checklist,
  Event,
  FormatListNumbered,
  Notes,
} from '@mui/icons-material';
import { EditWorkflowCard } from '../Workflow';
import { EventList } from '../Event';
import { EditDocumentCard } from '../Document/EditDocumentCard';

export const PlannerWrapper = () => {
  const { setSelectedTab } = useContext(PlannerContext);

  const tabs = [
    { name: 'Tasks', icon: <Checklist />, component: TaskList },
    {
      name: 'Workflows',
      icon: <FormatListNumbered />,
      component: WorkflowList,
    },
    {
      name: 'Events',
      icon: <Event />,
      component: EventList,
    },
    {
      name: 'Documents',
      icon: <Notes />,
      component: DocumentList,
    },
  ];

  const handleSetSelectedTab = (selectedTab: number) => {
    switch (selectedTab) {
      case 0:
        setSelectedTab('task');
        break;
      case 1:
        setSelectedTab('workflow');
        break;
      case 2:
        setSelectedTab('workflow');
        break;
      case 3:
        setSelectedTab('document');
        break;
    }
  };

  return (
    <Container>
      <TagsAndFilters />
      <PlannerSearch />
      <TabView tabs={tabs} onSelectTab={handleSetSelectedTab} />
      <EditTaskCard />
      <EditWorkflowCard />
      <EditDocumentCard />
    </Container>
  );
};

export const Planner = () => {
  return (
    <PlannerProvider>
      <PlannerWrapper />
    </PlannerProvider>
  );
};
