import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeAnnouncement } from '../../../types';

export const usePostAnnouncement = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (announcement: TypeAnnouncement) =>
      axios.post(`api/announcement`, announcement).then((res) => res.data),
    {
      onMutate: (announcement: TypeAnnouncement) => {
        queryClient.cancelQueries('announcement');

        const oldAnnouncement = queryClient.getQueryData([
          'announcement',
          announcement.id,
        ]);

        queryClient.setQueryData(
          ['announcement', announcement.id],
          announcement,
        );

        return () =>
          queryClient.setQueryData(
            ['announcement', announcement.id],
            oldAnnouncement,
          );
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('announcement');
        // queryClient.invalidateQueries(['announcement', variables.id]).then(_ => {})
      },
    },
  );
};
