import React, { useContext } from 'react';
import { AlertDialog } from './AlertDialog';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

type DeleteCourseDialogProps = {
  courseName: string;
  deleteFn: () => void;
};

export const DeleteCourseDialog = ({
  courseName,
  deleteFn,
}: DeleteCourseDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteCourseDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Delete ${courseName}?`,
    contentText: 'This will delete the entire course you created.',
    dismissiveAction: (
      <Button color="primary" onClick={() => closeDialog('deleteCourseDialog')}>
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={deleteFn}
      >
        Delete {courseName}
      </Button>
    ),
  };

  return <AlertDialog dialogProps={{ open: deleteCourseDialog }} {...props} />;
};
