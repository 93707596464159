import React, { useContext } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { AppearanceContext } from '../../../providers/AppearanceProvider';

type AlertDialogProps = {
  dialogProps: DialogProps;
  title?: string;
  contentText: string;
  dismissiveAction?: React.ReactNode;
  confirmingAction?: React.ReactNode;
  acknowledgementAction?: React.ReactNode;
};
export const AlertDialog = ({
  dialogProps,
  title,
  contentText,
  dismissiveAction,
  confirmingAction,
  acknowledgementAction,
}: AlertDialogProps) => {
  const { defaultTheme } = useContext(AppearanceContext);
  const styles = {
    noTitlePadding: {
      paddingTop: defaultTheme.spacing(3),
    },
  };
  if ((dismissiveAction || confirmingAction) && acknowledgementAction) {
    console.error(
      'Dialogs should contain a maximum of two actions. ' +
        'If a single action is provided, it must be an acknowledgement action. ' +
        'If two actions are provided, one must be a confirming action, and the other a dismissing action. ' +
        'Providing a third action such as “Learn more” is not recommended as it navigates the user away from the dialog, leaving the dialog task unfinished. ' +
        'https://material.io/design/components/dialogs.html#actions',
    );

    return null;
  }

  return (
    <Dialog {...dialogProps}>
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent sx={title ? null : styles.noTitlePadding}>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>

      {(dismissiveAction || confirmingAction || acknowledgementAction) && (
        <DialogActions>
          {dismissiveAction}
          {confirmingAction}
          {acknowledgementAction}
        </DialogActions>
      )}
    </Dialog>
  );
};
