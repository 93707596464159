import React, { useContext, useEffect, useState } from 'react';
import {
  useGetCoachesByProfile,
  useGetCoachTeams,
  useGetLeagueDays,
  useGetPracticeAvailabilityByTeam,
  useGetPracticeSlotDaysByTeam,
} from '../../../api';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  TypeCoach,
  TypePracticeAvailabilityDay,
  TypePracticeDivisionGroup,
  TypeTeam,
} from '../../../types';
import {
  PracticeAvailabilityList,
  PracticeSelectionOpen,
  PracticeSlotDayList,
} from '../Practice';
import { Loader } from '../../_common';
import { useSubscription } from 'react-stomp-hooks';
import { Link } from 'react-router-dom';
import { AuthContext, ProjectContext } from '../../../providers';
import { Launch } from '@mui/icons-material';
import moment from 'moment/moment';

export const CoachTeams = () => {
  const { cld } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const [selectedCoach, setSelectedCoach] = useState<TypeCoach | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<TypeTeam | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [practiceDivisionGroup, setPracticeDivisionGroup] =
    useState<TypePracticeDivisionGroup | null>(null);
  const [mapImageLink, setMapImageLink] = useState<string | null>(null);
  // const [isAllCapacityFilled, setIsAllCapacityFilled] = useState(false);
  const [dayTimesMap, setDayTimesMap] = useState({});
  const [daysMap, setDaysMap] = useState({});
  const league = selectedCoach?.league;

  useSubscription('/topic/coach/practice', (message) =>
    setMessage(message.body),
  );

  const { data: coachData, isLoading: coachLoading } = useGetCoachesByProfile(
    profile?.id!!,
    { enabled: !!profile?.id },
  );
  const { data: coachTeams, isLoading: coachTeamsLoading } = useGetCoachTeams(
    selectedCoach?.id!!,
    { enabled: !!coachData },
  );
  const { data: leagueDaysData, isLoading: leagueDaysLoading } =
    useGetLeagueDays(league?.id!!, {
      enabled: !!league?.id,
    });
  const {
    data: practiceSlotDays,
    isLoading: practiceSlotDaysLoading,
    refetch: practiceSlotDaysRefetch,
  } = useGetPracticeSlotDaysByTeam(selectedTeam?.id!!, {
    enabled: !!selectedTeam,
  });
  const {
    data: practiceSlotAvailabilityData,
    isLoading: practiceSlotAvailabilityLoading,
    refetch: practiceSlotAvailabilityRefetch,
  } = useGetPracticeAvailabilityByTeam(selectedTeam?.id!!, {
    enabled: !!selectedTeam,
  });

  useEffect(() => {
    if (practiceSlotAvailabilityData) {
      setPracticeDivisionGroup(
        practiceSlotAvailabilityData[0]?.practiceDivisionGroup || null,
      );
    }
  }, [practiceSlotAvailabilityData]);

  useEffect(() => {
    if (!selectedCoach && coachData?.length) {
      setSelectedCoach(coachData[0]);
    }
  }, [coachData]);

  const defaultSelectedTeam = coachTeams?.length ? coachTeams[0] : null;
  const handleCoachChange = (value: TypeCoach | null) => {
    setSelectedCoach(value);
    setSelectedTeam(defaultSelectedTeam);
  };

  useEffect(() => {
    setSelectedTeam(defaultSelectedTeam);
  }, [coachTeams, selectedCoach]);

  useEffect(() => {
    if (message === 'update') {
      console.log('update');
      practiceSlotAvailabilityRefetch();
      practiceSlotDaysRefetch();
      setMessage(null);
    }
  }, [message]);

  useEffect(() => {
    if (!cld || !selectedTeam?.division?.mapImage?.imageUrl) return;
    setMapImageLink(
      cld.image(selectedTeam?.division?.mapImage?.imageUrl).toURL(),
    );
  }, [cld, selectedTeam]);

  // useEffect(() => {
  //   if (!practiceSlotAvailabilityData) return;
  //   setIsAllCapacityFilled(
  //     practiceSlotAvailabilityData.every((availability) =>
  //       availability.practiceAvailabilityDayList.every(
  //         (availabilityDay) => availabilityDay.isFull,
  //       ),
  //     ),
  //   );
  // }, [practiceSlotAvailabilityData]);

  useEffect(() => {
    if (!practiceSlotDays) return;
    setDayTimesMap(
      practiceSlotDays.reduce((map, practiceSlotDay) => {
        const key = `${practiceSlotDay.leagueDay?.id!!}-${practiceSlotDay
          .leagueTime?.id!!}`;
        map[key] = true;
        return map;
      }, {}),
    );
    setDaysMap(
      practiceSlotDays.reduce((map, practiceSlotDay) => {
        const key = practiceSlotDay.leagueDay?.id!!;
        map[key] = true;
        return map;
      }, {}),
    );
  }, [practiceSlotDays]);

  const hasPracticeAtSameDayTime = (
    practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ): boolean => {
    return (
      `${practiceAvailabilityDay.leagueDay!!.id}-${
        practiceAvailabilityDay.leagueTime!!.id
      }` in dayTimesMap
    );
  };

  const hasPracticeOnSameDay = (
    practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ): boolean => {
    return practiceAvailabilityDay.leagueDay?.id!! in daysMap;
  };

  const momentOpenTime = moment(league?.selectionOpenDateTime);
  const momentCloseTime = moment(league?.selectionCloseDateTime);
  const isAfterOpenTime =
    momentOpenTime.isValid() && moment().isAfter(momentOpenTime);
  const isBeforeCloseTime =
    momentCloseTime.isValid() && moment().isBefore(momentCloseTime);
  const isSelectionOpen =
    (!momentOpenTime.isValid() || isAfterOpenTime) &&
    (!momentCloseTime.isValid() || isBeforeCloseTime);

  if (
    coachLoading ||
    coachTeamsLoading ||
    practiceSlotDaysLoading ||
    practiceSlotAvailabilityLoading ||
    leagueDaysLoading
  ) {
    return <Loader />;
  }

  if (coachData?.length === 0) {
    return (
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h6'} mt={2}>
              No Teams
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              You are not assigned to any teams. Please contact your league
              coordinator.
            </Alert>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant={'h6'} mt={2}>
            A message from your coordinator
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid
          item
          xs={12}
          style={{
            whiteSpace: 'pre-line',
          }}
          mb={4}
        >
          <Typography variant={'body1'}>
            {selectedTeam?.division?.league?.message}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={selectedCoach}
            id="selectedCoach"
            options={coachData || []}
            getOptionLabel={(option) =>
              `${option.league?.name} - ${option.name}`
            }
            filterSelectedOptions
            disableClearable={selectedCoach !== null}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => handleCoachChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Coach" placeholder="Coach" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={selectedTeam}
            id="selectedTeam"
            options={coachTeams || []}
            getOptionLabel={(option) =>
              `${option.division?.name} - ${option.name}`
            }
            filterSelectedOptions
            disableClearable={selectedTeam !== null}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => setSelectedTeam(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Team" placeholder="Team" />
            )}
          />
        </Grid>
      </Grid>
      <PracticeSlotDayList
        team={selectedTeam}
        practiceSlotDays={practiceSlotDays || []}
        practiceAvailabilities={practiceSlotAvailabilityData || []}
        practiceDivisionGroup={practiceDivisionGroup}
        isSelectionOpen={isSelectionOpen}
        hasPracticeAtSameDayTime={hasPracticeAtSameDayTime}
      />
      <Grid container spacing={2} mt={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant={'h6'}>
            {selectedTeam?.division?.name} Division Schedule
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {mapImageLink ? (
            <Link to={mapImageLink} target={'_blank'}>
              <Button
                variant="outlined"
                endIcon={<Launch />}
                disabled={!mapImageLink}
              >
                View Field Map
              </Button>
            </Link>
          ) : (
            <Button
              variant="outlined"
              disabled={!mapImageLink}
              endIcon={<Launch />}
            >
              No Field Map Available
            </Button>
          )}
        </Grid>
      </Grid>
      <Box mb={2}>
        <PracticeSelectionOpen
          isSelectionOpen={isSelectionOpen}
          momentOpenTime={momentOpenTime}
          momentCloseTime={momentCloseTime}
        />
      </Box>
      <PracticeAvailabilityList
        team={selectedTeam}
        leagueDays={leagueDaysData || []}
        practiceAvailabilities={practiceSlotAvailabilityData || []}
        practiceSlotDays={practiceSlotDays || []}
        practiceDivisionGroup={practiceDivisionGroup || undefined}
        isSelectionOpen={isSelectionOpen}
        hasPracticeOnSameDay={hasPracticeOnSameDay}
        hasPracticeAtSameDayTime={hasPracticeAtSameDayTime}
      />
    </Container>
  );
};
