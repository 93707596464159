import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  TypeLeagueDay,
  TypePracticeAvailability,
  TypePracticeAvailabilityDay,
  TypePracticeDivisionGroup,
  TypePracticeSlotDay,
  TypeTeam,
} from '../../../types';
import { usePostPracticeAvailabilityDay } from '../../../api';
import { DialogContext, ProjectContext } from '../../../providers';
import { ClaimPracticeAvailabilityDialog } from '../../_common/Dialogs';

type PracticeAvailabilityListProps = {
  team?: TypeTeam | null;
  leagueDays: TypeLeagueDay[];
  practiceAvailabilities: TypePracticeAvailability[];
  practiceSlotDays?: TypePracticeSlotDay[];
  practiceDivisionGroup?: TypePracticeDivisionGroup;
  isSelectionOpen: boolean;
  hasPracticeOnSameDay: (
    _practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ) => boolean;
  hasPracticeAtSameDayTime: (
    _practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ) => boolean;
};

export const PracticeAvailabilityList = ({
  team,
  leagueDays,
  practiceAvailabilities,
  practiceSlotDays,
  practiceDivisionGroup,
  isSelectionOpen,
  hasPracticeOnSameDay,
  hasPracticeAtSameDayTime,
}: PracticeAvailabilityListProps) => {
  const { setSnackbar } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const [claimPracticeAvailabilityDay, setClaimPracticeAvailabilityDay] =
    useState<TypePracticeAvailabilityDay>();
  const { mutate: claimPractice } = usePostPracticeAvailabilityDay();

  const isAtCapacity =
    practiceSlotDays &&
    practiceDivisionGroup &&
    practiceSlotDays.length >= practiceDivisionGroup.slotsPerTeam!!;
  const allowSameDay = practiceDivisionGroup?.allowSameDayPractices;

  const handleClaimPracticeAvailability = (
    practiceAvailabilityDay?: TypePracticeAvailabilityDay,
  ) => {
    setClaimPracticeAvailabilityDay(practiceAvailabilityDay);
    openDialog('claimPracticeAvailabilityDialog');
  };

  const resetClaimPracticeAvailability = (error = false) => {
    setClaimPracticeAvailabilityDay(undefined);
    closeDialog('claimPracticeAvailabilityDialog');
    if (error) {
      setSnackbar('Error claiming practice slot', 'error');
    } else {
      setSnackbar('Practice slot claimed');
    }
  };

  const completeClaimPracticeAvailabilityDay = () => {
    if (claimPracticeAvailabilityDay && team) {
      claimPracticeAvailabilityDay.claimWithTeam = team;
      claimPractice(claimPracticeAvailabilityDay, {
        onSuccess: () => resetClaimPracticeAvailability(),
        onError: () => resetClaimPracticeAvailability(true),
      });
    }
  };

  const renderPracticeAvailabilityDay = (
    practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ) => {
    const isDayFull = practiceAvailabilityDay.isFull;
    const isClosed = !isSelectionOpen;
    const isClaimed = hasPracticeAtSameDayTime(practiceAvailabilityDay);
    const hasSameDay = hasPracticeOnSameDay(practiceAvailabilityDay);

    let text: string;
    let variant: 'contained' | 'outlined' = 'contained';
    let isButtonDisabled = true;

    if (isClaimed) {
      text = 'Claimed';
    } else if (isClosed) {
      text = 'Closed';
    } else if (isAtCapacity) {
      text = 'At Capacity';
    } else if (isDayFull) {
      text = 'Full';
    } else if (hasSameDay && !allowSameDay) {
      text = 'Has Same Day';
    } else {
      text = 'Claim';
      isButtonDisabled = false;
    }

    return (
      <Button
        variant={variant}
        onClick={() => handleClaimPracticeAvailability(practiceAvailabilityDay)}
        disabled={isButtonDisabled}
      >
        {text}
      </Button>
    );
  };

  const renderPracticeAvailability = (
    practiceAvailability: TypePracticeAvailability,
    index: number,
  ) => (
    <TableRow key={index}>
      <TableCell>{practiceAvailability?.leagueTime?.time}</TableCell>
      {practiceAvailability?.practiceAvailabilityDayList?.map(
        (practiceAvailabilityDay, index) => (
          <TableCell key={index}>
            {renderPracticeAvailabilityDay(practiceAvailabilityDay)}
          </TableCell>
        ),
      )}
    </TableRow>
  );

  if (!practiceAvailabilities || !leagueDays.length) {
    return <></>;
  }

  if (isAtCapacity) {
    return (
      <Box mb={2}>
        <Alert severity={'success'}>
          All practice times have been selected for this team. To make changes,
          use the swap actions above.
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <TableContainer>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              {leagueDays
                ?.filter((day) => day.enabled)
                .map((day, index) => (
                  <TableCell key={`day-${index}`}>{day.day}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {practiceAvailabilities?.map((practiceAvailability, index) =>
              renderPracticeAvailability(practiceAvailability, index),
            )}
            {Object.values(practiceAvailabilities).length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>No Practice Slots Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {team && claimPracticeAvailabilityDay && (
        <ClaimPracticeAvailabilityDialog
          practiceAvailabilityDay={claimPracticeAvailabilityDay}
          completeFn={completeClaimPracticeAvailabilityDay}
        />
      )}
    </>
  );
};
